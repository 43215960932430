import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {from, useMediaQuery} from 'styles/media';
import {useHistory} from "react-router-dom";
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {useCallback} from "react";
import {useDisclosure} from "@scriptaddicts/yamm-ui-components";

export const useConnect = () => {




  const manageSeatsDisclosure = useDisclosure({});

  const {id: workspaceId, urlPrefix, isLoading: isLoadingWorkspace} = useWorkspaceContext();
  const {subscriptionSeat, isSubscriptionSeatReady} = useSubscriptionSeat(workspaceId);
  const {push} = useHistory();

  const onAddSeats = useCallback(() => {
    const target = `${urlPrefix}/checkout/add-seats`;
    push(target);
  }, [urlPrefix, push]);

  const onRemoveUnusedSeats = useCallback(() => {
    const target = `${urlPrefix}/checkout/remove-seats`
    push(target);
  }, [urlPrefix, push]);


  const isMobile = !useMediaQuery(from.tablet);

  return {
    isLoading: isLoadingWorkspace || isSubscriptionSeatReady,
    isMobile,
    subscriptionSeat,
    onAddSeats,
    onRemoveUnusedSeats,
    manageSeatsDisclosure
  };
}
