import styled, { css } from 'styled-components';
import { RadioGroup, Spinner, Text } from '@scriptaddicts/yamm-ui-components';
import { from } from '../../../../../../styles/media';

export const StyledContainer = styled.div<{
  $onlyOne?: boolean;
}>`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  ${({ $onlyOne }) => {
    if (!$onlyOne) {
      return css`
        ${from.tablet} {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      `;
    }
    return css``;
  }}
`;

export const StyledIntervalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${from.tablet} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
`;

export const StyledCompleteFeatureListDiv = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
  gap: 25px;
`;

export const UpgradeTitle = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.gray900};
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
`;

export const SectionLoading = styled(Spinner).attrs({
  size: 32,
})`
  display: flex;
  justify-content: center;
  margin-top: 5vh;
`;
