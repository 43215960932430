import React, { createContext, FC, useContext } from 'react';
import { useConnect } from './connect';
import { DEFAULT_SEAT_CHECKOUT_DATA } from './utils';

const SeatCheckoutContext = createContext<ReturnType<typeof useConnect>>(
  DEFAULT_SEAT_CHECKOUT_DATA,
);

export const useSeatCheckoutContext = () => useContext(SeatCheckoutContext);

export const SeatCheckoutContextProvider: FC = ({ children }) => {
  const connect = useConnect();
  
  return (
    <SeatCheckoutContext.Provider value={connect}>
      {children}
    </SeatCheckoutContext.Provider>
  );
};
