import { useMemo } from 'react';
import { User } from 'model/user';
import { useValidatePromoteMembers } from 'data/hooks/useWorkspaceMembers';
import { WorkspaceMemberWithSeat } from 'seats/model/WorkspaceMemberWithSeats';
import { ActiveUsers } from '../types';
import {
  WorkspaceDetails, WorkspaceUser,
} from '../../../../../../../data/context/WorkspaceContext';
import { getRoleValue, RoleValue } from '../../../../../../../utils/roleValue';
import { WorkspaceSubscription } from '../../../../../../../model/workspaceBilling';
import {
  isSupportGivenSubscription,
} from '../../../../../../../utils/subscriptions';

const ACTIVE_STATUS = ['ACTIVE'];

export type UseActiveUsersProps = {
  selected: WorkspaceMemberWithSeat[];
  allUsers: WorkspaceMemberWithSeat[];
  me: User | null;
  workspaceId?: string;
  workspace?: WorkspaceDetails;
  workspaceUser?: WorkspaceUser;
  activeSubscription?: WorkspaceSubscription;
  isMultiplePaymentRestricted: boolean;
};
export const useActiveUsers = ({
  workspaceId,
  workspaceUser,
  workspace,
  me,
  selected,
  allUsers,
  activeSubscription,
  isMultiplePaymentRestricted,
}: UseActiveUsersProps) => {
  const { data: promotionRestrictedUsers } = useValidatePromoteMembers(
    isMultiplePaymentRestricted && workspace?.plan !== 'LEGACY'
      ? workspaceId
      : undefined,
  );

  return useMemo<ActiveUsers>(() => {
    const userRoleValue = getRoleValue(workspaceUser?.role ?? 'MEMBER');
    const canManageRoles =
      !!workspaceUser?.features.manageUserRoles &&
      userRoleValue >= RoleValue.OWNER;
    const activeUsers = selected.filter((s) =>
      ACTIVE_STATUS.includes(s?.status),
    );

    const isSupportGiven =
      activeSubscription && isSupportGivenSubscription(activeSubscription);
    const isLegacy = workspace?.plan === 'LEGACY';

    const isOwnerSelected = activeUsers.some(
      ({ id: userId, role }) => userId === me?.id || role === 'OWNER',
    );

    // display member/admin
    const canChangeRole =
      workspace?.plan !== 'FREE' &&
      canManageRoles &&
      activeUsers.every(({ role }) => role !== 'OWNER');

    return {
      count: activeUsers.length,
      user: activeUsers.length === 1 ? activeUsers[0] : undefined,
      list: activeUsers,
      // false = do not display
      features: {
        change: {
          // can change user role (member/admin)
          role: canChangeRole,
        },
        remove: !isSupportGiven && userRoleValue >= RoleValue.ADMIN,
      },
      type: activeUsers[0]?.plan,
      role: activeUsers[0]?.role,
      isPersonal: activeUsers[0]?.isPersonal,
      warnings: {
        remove: isOwnerSelected ? 'CANNOT_REMOVE_OWNER' : undefined,
        subjectEmails: (() => {
          if (workspace?.plan === 'LEGACY') {
            return [];
          }
          return [];
        })(),
        cannotRemovePaidUsers: false,
      },
      // true = disabled (but can bi displayed)
      // to completely hide element refer to features object
      disabled: {
        change: {
          type:
            true,
          role: isLegacy,
        },
        remove:
          isSupportGiven || isOwnerSelected,
      },
    };
  }, [
    activeSubscription,
    selected,
    allUsers,
    promotionRestrictedUsers,
    me,
    workspaceUser,
    workspace,
  ]);
};
