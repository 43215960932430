import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { MainContainerLoader } from 'components/MainContainer';
import Billing from 'seats/containers/Main/Workspaces/Billing';
import UserSettings from 'seats/containers/Main/Workspaces/UserSettings';
import GeneralSettings from 'containers/Main/Workspaces/GeneralSettings';
import { useConnect } from 'containers/Main/Workspaces/connect';

const Workspaces = () => {
  const {
    url,
    path,
    isLoading,
    isNotAuthorized,
    isOwner,
    isUsersPageRestricted,
    canAccessUsers,
  } = useConnect();

  if (isLoading || isNotAuthorized) return <MainContainerLoader />;

  return (
    <Switch>
      {isOwner && (
        <Route path={`${path}/general`}>
          <GeneralSettings />
        </Route>
      )}
      {!isUsersPageRestricted && canAccessUsers && (
        <Route path={`${path}/users`}>
          <UserSettings />
        </Route>
      )}
      <Route path={`${path}/billing`}>
        <Billing />
      </Route>
      <Route path={`${path}/upgrade`}>
        <Redirect to={`${url}/billing`} />
      </Route>

      {isOwner ? (
        <Route path={path}>
          <Redirect to={`${url}/general`} />
        </Route>
      ) : (
        <>
          {!isUsersPageRestricted ? (
            <Route path="*">
              <Redirect to={`${url}/users`} />
            </Route>
          ) : (
            <Route path="*">
              <Redirect to={`${url}/billing`} />
            </Route>
          )}
        </>
      )}
    </Switch>
  );
};

export default Workspaces;
