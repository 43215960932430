import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWorkspaceActiveSubscriptionV2 } from 'seats/data/hooks/useSeatsWorkspaceBilling';
import { useWorkspaceContext } from '../../../../../../data/context/WorkspaceContext';
import { BillingInterval } from '../../../../../../model/workspaceBilling';
import { usePrices } from '../../../../../../data/hooks/usePaymentPlans';
import { useMe } from '../../../../../../data/hooks/useMe';
import { isGmailAddress } from '../../../../../../utils/userUtils';
import { DEFAULT_INTERVAL } from '../../../../../../data/constants';
import {clearSeatQuantityInSessionStorage} from "../../../../../data/context/CheckoutContext/utils";

export const useConnect = () => {
  const { data: me } = useMe({
    keepPreviousData: true,
    staleTime: 60000,
  });

  const {
    id,
    urlPrefix,
    details: workspaceDetails,
    restrictions,
  } = useWorkspaceContext();

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
  } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id,
  });
  const [interval, setBillingInterval] = useState<BillingInterval>(
    DEFAULT_INTERVAL,
  );
  const { push } = useHistory();
  const { prices, isLoading: isLoadingPrices } = usePrices();

  const navigate = () => {
    clearSeatQuantityInSessionStorage();
    push(`${urlPrefix}/checkout/details`);
  };

  return {
    isLoadingSubscription,
    isLoadingPrices,
    plan: subscription?.workspacePlan,
    interval,
    setBillingInterval,
    navigate,
    prices,
    legacyPlanType: workspaceDetails?.legacyType,
    isGmail: me ? isGmailAddress(me.email) : false,
    restrictions: {
      isMonthlyBillingRestricted: !!restrictions?.MONTHLY_BILLING,
      isPremiumPlanRestricted: !!restrictions?.PREMIUM_PLAN,
      isUsersPageRestricted: !!restrictions?.USERS_PAGE,
      isTransferWorkspaceRestricted: !!restrictions?.TRANSFER_WORKSPACE,
      isWorkspaceDashboardRestricted: !!restrictions?.WORKSPACE_DASHBOARD,
    },
  };
};
