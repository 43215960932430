import {captureException} from "@sentry/minimal";
import {
  BillingInterval,
  BillingWorkspacePlan,
} from "model/workspaceBilling";
import {SeatCheckoutContextType, SeatCheckoutFormValues} from 'seats/data/context/CheckoutContext/types';
import {SEAT_CHECKOUT_SESSION_FORM_KEY, SEAT_CHECKOUT_SESSION_QUANTITY} from "seats/data/constants";

export const DEFAULT_SEAT_CHECKOUT_DATA: SeatCheckoutContextType = {
  plan: 'PRO',
  billingInterval: 'YEARLY',
  seatQuantity: 1,
  isLoading: true,
  checkoutFlowType: 'EMPTY_FLOW',
  checkoutForm: {
    city: '',
    code: '',
    companyName: '',
    country: '',
    isBusinessPurchase: false,
    name: '',
    street: '',
    state: '',
    vatNumber: '',
  },
  setSeatQuantity: () => { },
  setCheckoutForm: () => { }
};

export const getDefaultPlan = (
  queryValue: string | null,
): BillingWorkspacePlan => {
  if (queryValue === 'PRO') {
    return queryValue;
  }
  return DEFAULT_SEAT_CHECKOUT_DATA.plan;
};

export const getDefaultInterval = (
  queryValue: string | null,
): BillingInterval => {
  if (queryValue === 'YEARLY') {
    return queryValue;
  }
  return DEFAULT_SEAT_CHECKOUT_DATA.billingInterval;
};

export const getInitialCheckoutForm = () => {
  try {
    const sessionForm = sessionStorage.getItem(SEAT_CHECKOUT_SESSION_FORM_KEY);
    if (sessionForm != null) {
      return JSON.parse(sessionForm) as SeatCheckoutFormValues;
    }
  } catch (e) {
    captureException(new Error('Failed to parse seat checkout form from session'));
  }

  return DEFAULT_SEAT_CHECKOUT_DATA.checkoutForm;
};

export const getInitialSeatQuantity = () => {
  try {
    const sessionSeatQuantity = sessionStorage.getItem(SEAT_CHECKOUT_SESSION_QUANTITY);
    if (sessionSeatQuantity != null) {
      return JSON.parse(sessionSeatQuantity) as number;
    }
  } catch (e) {
    captureException(new Error('Failed to parse seat checkout quantity from session'));
  }
  return DEFAULT_SEAT_CHECKOUT_DATA.seatQuantity;
};

export const setSeatQuantityInSessionStorage = (quantity: number) => {
  try {
    sessionStorage.setItem(SEAT_CHECKOUT_SESSION_QUANTITY, JSON.stringify(quantity))
  } catch (e) {
    captureException(new Error('Failed to save seat checkout quantity to session'));
  }
};

export const clearSeatQuantityInSessionStorage = () => {
  try {
    sessionStorage.removeItem(SEAT_CHECKOUT_SESSION_QUANTITY)
  } catch (e) {
    captureException(new Error('Failed to save seat checkout quantity to session'));
  }
};