import { WorkspaceSubscriptionV2 } from 'seats/model/workspaceBilling';
import { WorkspaceSubscription } from '../../model/workspaceBilling';

export const isOfflineSubscription = (
  subscription: WorkspaceSubscription | WorkspaceSubscriptionV2,
): boolean => {
  return subscription.paymentSource === 'OFFLINE';
};

export const isSupportGivenSubscription = (
  subscription: WorkspaceSubscription | WorkspaceSubscriptionV2,
): boolean => {
  return subscription.paymentSource === 'SUPPORT';
};
