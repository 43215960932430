import {isGmailAddress} from "utils/userUtils";
import {PaidUserPlan,} from "seats/data/requests/workspaceBilling";
import {ComputeStripePaymentInfoV2} from "seats/model/workspaceBilling";

export const getAvailablePlanForEmail = (
  userEmail: string,
): PaidUserPlan => {
  if (isGmailAddress(userEmail)) {
    return 'PRO_PERSONAL';
  }
  return 'PRO';
};

export const getPaidToday = (paymentInfo: ComputeStripePaymentInfoV2) => {
  return paymentInfo.totalAmount;
};

export const getChargedToday = (paymentInfo: ComputeStripePaymentInfoV2) =>
  paymentInfo.chargedToday;
