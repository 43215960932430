import styled from 'styled-components';
import {
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background: ${({ theme }) => theme.colors.white};

  /* Shadow/Small */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const CardTitle = styled(DefaultText)`
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const CardSubtitle = styled(DefaultText)`
  color: ${({ theme }) => theme.colors.blue700};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 24px */
  letter-spacing: -0.099px;
`;
