import React, {useEffect, useState} from "react";
import {ReactComponent as SpinnerMinusIcon} from 'assets/images/icons/input-spinner-minus.svg';
import {ReactComponent as SpinnerMinusInvalidIcon} from 'assets/images/icons/input-spinner-minus-invalid.svg';
import {ReactComponent as SpinnerPlusIcon} from 'assets/images/icons/input-spinner-plus.svg';
import {ReactComponent as SpinnerPlusInvalidIcon} from 'assets/images/icons/input-spinner-plus-invalid.svg';
import {ReactComponent as WarningIcon} from 'assets/images/icons/alert-triangle-red.svg';

import {
  DecreaseButton,
  IncreaseButton, NumberInputSpinnerBody,
  NumberInputSpinnerComponent,
  NumberInputSpinnerContainer,
  NumberInputSpinnerValidation,
  SpinnerInput, ValidationMessage
} from "seats/components/NumberInputSpinner/styles";

interface NumberInputSpinnerProps {
  minValue: number;
  maxValue: number;
  initialValue: number;
  onChange: (value: number) => void;
  onInvalid: () => void;
}

export const NumberInputSpinner = ({
    minValue,
    maxValue,
    initialValue,
    onChange,
    onInvalid,
    children,
  }: React.PropsWithChildren<NumberInputSpinnerProps>) => {

    const [value, setValue] = useState<string | number>(initialValue);

    useEffect(() => {setValue(initialValue)}, [initialValue])

    const [isValid, setIsValid] = useState(true);

    function validateNumberInput(inputValue: string, min: number, max: number): boolean {
      if (!inputValue) {
        return false;
      }
      const num = Number(inputValue);
      return Number.isInteger(num) && num >= min && num <= max;
    }

    const isInteger = (str: string) => {
      return /^\d+$/.test(str);
    };

    function doHandleOnChange(typedValue: string | number) {
      setValue(typedValue);
      const valid = validateNumberInput(
        typeof typedValue === "number" ? typedValue.toString() : typedValue,
        minValue,
        maxValue
      );
      setIsValid(valid);
      if (valid) {
        onChange(Number(typedValue));
      } else {
        onInvalid();
      }
    }

    const handleIncrement = () => {
      if (isInteger(value.toString())) {
        const changedValue = Number(value) + 1;
        const valid = validateNumberInput(changedValue.toString(), minValue, maxValue);
        if (valid) {
          doHandleOnChange(changedValue);
        }
      }
    };

    const handleDecrement = () => {
      if (isInteger(value.toString())) {
        const changedValue = Number(value) - 1;
        const valid = validateNumberInput(changedValue.toString(), minValue, maxValue);
        if (valid) {
          doHandleOnChange(changedValue);
        }
      }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const typedValue = e.target.value;
      doHandleOnChange(typedValue);
    };

    return (
      <NumberInputSpinnerContainer>
        <NumberInputSpinnerBody>
          <NumberInputSpinnerComponent isValid={isValid}>
            <DecreaseButton
              className={value === minValue ? 'disabled' : ''}
              onClick={handleDecrement}
            >
              {isValid ? (<SpinnerMinusIcon width="24px" height="24px"/>) : (
                <SpinnerMinusInvalidIcon width="24px" height="24px"/>)}
            </DecreaseButton>
            <SpinnerInput
              type="number"
              value={value}
              onChange={handleOnChange}
            />
            <IncreaseButton className={value === maxValue ? 'disabled' : ''} onClick={handleIncrement}>
              {isValid ? (<SpinnerPlusIcon width="24px" height="24px"/>) : (
                <SpinnerPlusInvalidIcon width="24px" height="24px"/>)}
            </IncreaseButton>
          </NumberInputSpinnerComponent>
          {children}
        </NumberInputSpinnerBody>
        {
          !isValid && (
                     <NumberInputSpinnerValidation>
                       <WarningIcon width="16px" height="16px"/>
                       <ValidationMessage>The number must be between {minValue} and {maxValue}.</ValidationMessage>
                     </NumberInputSpinnerValidation>
                   )
        }
      </NumberInputSpinnerContainer>
    );
  }
;