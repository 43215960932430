import {
  BillingCurrency,
} from 'model/workspaceBilling';
import {PurchasePlanDisplayV2} from 'seats/containers/Checkout/common/types';
import {PaymentPlan} from 'model/paymentPlans';
import {ComputeStripePaymentInfoV2, StripePlanV2} from "seats/model/workspaceBilling";
import {getChargedToday, getPaidToday} from "seats/utils/workspaceBillingUtil";
import {formatPriceSignOnly} from "seats/utils/priceFormat";
import {UserWorkspacePlan, WorkspacePlan} from "model/workspace";

export const getFormattedChargedToday = (
  paymentInfo: ComputeStripePaymentInfoV2,
): string =>
  formatPriceSignOnly(getChargedToday(paymentInfo), paymentInfo.currency, 2);

export const getFormattedTotalDue = (
  paymentInfo: ComputeStripePaymentInfoV2,
): string => {
  let paidToday = getPaidToday(paymentInfo);
  if (paymentInfo.taxRate > 0) {
    paidToday += (paidToday * paymentInfo.taxRate) / 100;
  }

  return formatPriceSignOnly(paidToday, paymentInfo.currency, 2);
};

export const getFormattedTaxAmountPaidToday = (
  paymentInfo: ComputeStripePaymentInfoV2,
) =>
  formatPriceSignOnly(
    getPaidToday(paymentInfo) * (paymentInfo.taxRate / 100),
    paymentInfo.currency,
    2,
  );

export const getFormattedPaidToday = (paymentInfo: ComputeStripePaymentInfoV2) =>
  formatPriceSignOnly(getPaidToday(paymentInfo), paymentInfo.currency, 2);


export function formatUserWorkspacePlan(plan: UserWorkspacePlan) {
  const planMapping = {
    FREE: 'Free',
    PRO: 'Pro',
    PRO_PERSONAL: 'Pro Personal',
  };
  return planMapping[plan];
}

export function formatWorkspacePlan(plan: WorkspacePlan) {
  const planMapping = {
    FREE: 'Free',
    PRO: 'Pro',
    PREMIUM: 'Premium',
    LEGACY: 'Legacy',
  };
  return planMapping[plan];
}

export const getPurchasePlansDisplayV2 = (
  stripePlans: StripePlanV2[],
  paymentPlans: PaymentPlan[],
  currency: BillingCurrency,
) => {
  const purchasePlans: PurchasePlanDisplayV2[] = stripePlans.flatMap((stripePlan) => {
    const paymentPlan = paymentPlans.find((plan) => plan.stripePriceId === stripePlan.stripePriceId);
    return paymentPlan
      ? [
        {
          totalAmount: stripePlan.totalAmount,
          quantity: stripePlan.quantity,
          userPlan: paymentPlan.userPlan,
          currency,
        },
      ]
      : [];
  });
  return purchasePlans;
};

export const getSubscriptionBasePrice = (
  paymentInfo: ComputeStripePaymentInfoV2,
) => paymentInfo.totalAmount;

export const getSubscriptionPriceWithTax = (
  paymentInfo: ComputeStripePaymentInfoV2,
) => {
  let basePrice = getSubscriptionBasePrice(paymentInfo);
  if (paymentInfo.taxRate > 0) {
    basePrice += (basePrice * paymentInfo.taxRate) / 100;
  }

  return basePrice;
};

