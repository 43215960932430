import {
  Button,
  Drawer,
  FlexContainer,
  FlexSpacer,
  Alert,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import ResponsiveTable from 'components/ResponsiveTable';
import React from 'react';
import { plurify } from 'utils/plurify';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerTitle,
} from 'components/MainContainer';
import { WorkspaceMemberWithSeat } from 'seats/model/WorkspaceMemberWithSeats';
import { useConnect } from './connect';
import { ManageUser } from './ManageUser';
import {
  Container,
  InfoContainer, ManageSeatsContainer, ManageSeatsLink, ManageSeatsText, SeatsStatusTextInnerContainer,
  TableContainer,
} from './styles';
import Empty from './table/empty';
import NotFound from './table/notFound';
import UserAlerts from './UserAlerts';
import UserSettingsInfo from './UserSettingsInfo';
import {Of, SeatsStatusTextContainer, SeatsUsed, SeatsUsedContainer} from "../Billing/CurrentPlan/styles";
import {ManageSeats} from "../../../../widgets/ManageSeats";
import {AssignUserToSeat} from "../../../../widgets/AssignUserToSeat";

const UserSettings = () => {
  const {
    id,
    subscriptionSeat,
    name,
    isLoading,
    isBusy,
    members,
    columns,
    tableStateManager,
    addUser,
    addSeats,
    manage,
    userAlerts,
    isSupportSubscription,
    onContactUs,
    canManageUsers,
    canManageBilling,
    handleTableSettingsUpdate,
  } = useConnect();
  return (
    <MainContainer title="Users">
      <MainContainerHeader direction="vertical">
        <MainContainerTitle append={name}>Users</MainContainerTitle>
        {canManageUsers &&  <UserSettingsInfo/>}
      </MainContainerHeader>
      <Container>
        {!isLoading && (
          <InfoContainer>
            <FlexContainer flex="vertical" gap="large">
              {isSupportSubscription && (
                <Alert
                  type="info"
                  append={
                    <Button
                      aria-label="contact us"
                      onClick={onContactUs}
                      variant="info"
                    >
                      Contact us
                    </Button>
                  }
                >
                  <Text>
                    You are on an offline managed subscription. Please contact{' '}
                    us if you need changes.
                  </Text>
                </Alert>
              )}
              <UserAlerts {...userAlerts} />
              {addUser.feature && (
                <FlexContainer
                  flex="horizontal"
                  style={{ alignItems: 'center' }}
                >
                  <FlexContainer flex="vertical" gap="base">
                    <SeatsStatusTextContainer>
                      <SeatsStatusTextInnerContainer>
                        <SeatsUsedContainer>
                          <Of>{`${subscriptionSeat.assignedSeats} of ${subscriptionSeat.totalSeats} `}</Of>
                          <SeatsUsed>{plurify(subscriptionSeat.totalSeats, 'seat', 'seats')} used</SeatsUsed>
                        </SeatsUsedContainer>
                        {canManageBilling && (
                          <ManageSeatsContainer>
                            <ManageSeatsText>(</ManageSeatsText>
                            <ManageSeatsLink onClick={() => { addSeats.onOpen(); }}>Manage seats</ManageSeatsLink>
                            <ManageSeatsText>)</ManageSeatsText>
                          </ManageSeatsContainer>
                        )}
                      </SeatsStatusTextInnerContainer>
                    </SeatsStatusTextContainer>
                  </FlexContainer>
                  {!addUser.restricted && canManageUsers && (
                    <>
                      <FlexSpacer />
                      <ManageSeats {...addSeats}/>
                      <AssignUserToSeat {...addUser}
                                        workspaceId={id}
                                        onSuccess={(props) => {
                                          addUser.setUserAlerts(props);
                                        }}
                      />
                      <Button
                        data-log-event="open_invitation_modal"
                        data-log-event-data={JSON.stringify({
                          displayName: 'Add users',
                        })}
                        aria-label="Add users"
                        variant="primary"
                        icon="users"
                        onClick={()=>{
                          if (subscriptionSeat.hasAvailableSeats()) {
                            addUser.onOpen();
                          } else {
                            addSeats.onOpen();
                          }
                        }}
                        disabled={isLoading || isBusy}
                      >
                        Add users
                      </Button>
                    </>
                  )}
                </FlexContainer>
              )}

            </FlexContainer>
          </InfoContainer>
        )}
        <Drawer
          side={<ManageUser {...manage} />}
          show={manage.show}
          close="none"
        >
          <TableContainer>
            <ResponsiveTable<WorkspaceMemberWithSeat>
              selectable={canManageUsers}
              sortable
              data={members}
              loading={isLoading}
              columns={columns}
              empty={<Empty />}
              notFound={<NotFound />}
              tableStateManager={tableStateManager}
              handleTableSettingsUpdate={handleTableSettingsUpdate}
              disabled={isBusy}
            />
          </TableContainer>
        </Drawer>
      </Container>
    </MainContainer>
  );
};

export default UserSettings;
