import React from 'react';
import { Alert, Text } from '@scriptaddicts/yamm-ui-components';
import { plurify } from 'utils/plurify';
import {UserAlertsProps} from "containers/Main/Workspaces/UserSettings/UserAlerts/types";

const UserAlerts = ({
  inviteCount,
  addCount,
  roleChangedMembers,
  removedMembers,
  reInviteCount,
  cancelInviteCount,
  activatedMembers,
  addedUsers,
}: UserAlertsProps) => {

  return (
    <>
      {!!addedUsers && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            You successfully added {addedUsers} {plurify(addedUsers, 'user', 'users')} to your space.
          </Text>
        </Alert>
      )}
      {!!addCount && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              inviteCount,
              'The user has been added successfully.',
              'The users have been added successfully.',
            )}
          </Text>
        </Alert>
      )}
      {!!roleChangedMembers && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              roleChangedMembers.length,
              'The role of your user has been updated.',
              'The role of your users has been updated.',
            )}
          </Text>
        </Alert>
      )}
      {!!removedMembers && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              removedMembers.length,
              `${removedMembers[0]} has been successfully removed from your space.`,
              'The users have been successfully removed from your space.',
            )}
          </Text>
        </Alert>
      )}
      {!!reInviteCount && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              reInviteCount,
              'Your invitation has been sent again.',
              'Your invitations have been sent again.',
            )}
          </Text>
        </Alert>
      )}
      {!!cancelInviteCount && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              cancelInviteCount,
              'Your invitation has been canceled.',
              'Your invitations have been canceled.',
            )}
          </Text>
        </Alert>
      )}
      {!!activatedMembers && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            {plurify(
              activatedMembers.length,
              `${activatedMembers[0]} has been successfully activated.`,
              'The users have been successfully activated.',
            )}
          </Text>
        </Alert>
      )}
    </>
  );
};

export default UserAlerts;
