import React from 'react';
import { Column } from 'components/ResponsiveTable/types';
import {
  TableCell,
} from '@scriptaddicts/yamm-ui-components';
import { TableCellValue } from './styles';
import {WorkspaceMemberWithSeat} from "../../../../../model/WorkspaceMemberWithSeats";

export const getColumns = ({
  isMobile,
}: {
  isMobile: boolean;
  count?: number;
  domain?: string;
}) =>
  [
    {
      Header: 'User',
      accessor: 'name',
      colSpan: isMobile ? 5 : 2,
      group: isMobile,
      Cell: ({ value, row }) =>
        isMobile ? (
          <>
            <TableCell colSpan={2}>
              <TableCellValue width="semi-bold">
                {row.original.name}
              </TableCellValue>
            </TableCell>
            <TableCell colSpan={3}>{row.original.email}</TableCell>
          </>
        ) : (
          <TableCellValue width="semi-bold">{value}</TableCellValue>
        ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      colSpan: 3,
      Cell: ({ value }) => (
        <TableCellValue>{!isMobile ? value : undefined}</TableCellValue>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      colSpan: 1,
      Cell: ({ value }) => (
        <TableCellValue>
          {value === 'OWNER' && 'Owner'}
          {value === 'ADMIN' && 'Admin'}
          {value === 'MEMBER' && 'Member'}
        </TableCellValue>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      colSpan: 1,
      Cell: ({ value }) => {
        if (value === 'ACTIVE' || value === 'ACCEPTED') {
          return <TableCellValue variant="success">Active</TableCellValue>;
        }
        if (value === 'PENDING') {
          return <TableCellValue variant="disabled" style={{ fontStyle: "italic" }}>Pending</TableCellValue>;
        }
        return (
          <TableCellValue style={{ textTransform: 'capitalize' }}>
            {value.toLowerCase()}
          </TableCellValue>
        );
      },
    },
  ] as Column<WorkspaceMemberWithSeat>[];
