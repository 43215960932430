import styled from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const ItemIcon = styled.div``;

export const ItemGroupItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px 4px 8px;
  gap: 0px 8px;
`;

export const UserSettingsInfoDescriptionText = styled(Text)`
  align-self: stretch;
  color: ${(props) => props.theme.colors.gray300}
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.092px;
`;

export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;
