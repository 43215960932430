import styled from 'styled-components';
import { ReactComponent as Hamburger } from 'assets/images/icons/hamburger.svg';
import { ReactComponent as HelpCircle } from 'assets/images/icons/help-circle.svg';
import { from } from 'styles/media';
import { ButtonRouterLink } from 'components/Link';
import { Avatar } from '@scriptaddicts/yamm-ui-components';
import DefaultAccountDropdown from 'components/AccountDropdown';

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray50};

  padding: 12px 12px;

  ${from.tablet} {
    padding: 20px 32px;
  }
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  padding: 0;
`;

export const MenuIcon = styled(Hamburger)``;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const AccountPlaceholder = styled(Avatar).attrs({
  size: 34,
})``;

export const AccountDropdown = styled(DefaultAccountDropdown)`
  width: 32px;
  height: 32px;
`;

export const ButtonLink = styled(ButtonRouterLink).attrs({
  size: 'small',
})`
  font-size: 14px;
`;

export const Help = styled.a`
  display: flex;
  border-radius: 50%;

  :focus {
    outline-offset: 2px;
    outline-color: black;
  }
`;

export const HelpIcon = styled(HelpCircle)``;
