import styled from 'styled-components';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import DefaultCountrySelect from './CountrySelect';
import DefaultRadioInput from './RadioInput';

export const CountrySelect = styled(DefaultCountrySelect)`
  flex: 1;
`;

export const RadioInput = styled(DefaultRadioInput)`
  flex: 1;
`;

export const ColumnedRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.orange600};
  border: none;
  border-radius: 0.3125rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 1.5rem;
  padding: 0.625rem 1rem;

  &:disabled {
    background-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
