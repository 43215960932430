import {useEffect, useMemo, useState} from 'react';
import { useWorkspaceActiveSubscriptionV2 } from 'seats/data/hooks/useSeatsWorkspaceBilling';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import useQueryParams from 'utils/useQueryParams';
import {
  DEFAULT_SEAT_CHECKOUT_DATA,
  getDefaultInterval,
  getDefaultPlan,
  getInitialCheckoutForm,
  getInitialSeatQuantity,
  setSeatQuantityInSessionStorage,
} from 'seats/data/context/CheckoutContext/utils';
import { SeatCheckoutContextType } from 'seats/data/context/CheckoutContext/types';
import { INTERVAL, PLAN } from 'data/queryParams';
import {useMe} from "../../../../data/hooks/useMe";
import {isGmailAddress} from "../../../../utils/userUtils";



export const useConnect = (): SeatCheckoutContextType => {
  const queryParams = useQueryParams();
  const { id, details: workspaceDetails } = useWorkspaceContext();

  const [checkoutForm, setCheckoutForm] = useState(getInitialCheckoutForm());
  const [seatQuantity, setSeatQuantity] = useState(getInitialSeatQuantity());

  const {data: me, isLoading: isLoadingUser} = useMe();
  const isGmail: boolean | null  = me?.email ? isGmailAddress(me.email) : null;

  useEffect(() => {
    setSeatQuantityInSessionStorage(seatQuantity);
  },[seatQuantity])

  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id,
  });

  const plan = getDefaultPlan(queryParams.get(PLAN));
  const billingInterval = getDefaultInterval(queryParams.get(INTERVAL));

  const checkoutFlowType = useMemo(() => {
    if (window.location.pathname.endsWith('add-seats')) {
      return 'ADDITIONAL_SEATS';
    }
    if (workspaceDetails) {
      if (isGmail === true) {
        return 'GMAIL_PURCHASE';
      }
      if (isGmail === false) {
        return 'DOMAIN_PURCHASE';
      }
    }
    return DEFAULT_SEAT_CHECKOUT_DATA.checkoutFlowType;
  }, [plan, activeSubscription, workspaceDetails, isGmail]);

  const [isSeatQuantityReady, setIsSeatQuantityReady] = useState<boolean>(true)
  useState<boolean>(true);
  return {
    plan,
    seatQuantity,
    setSeatQuantity,
    billingInterval,
    isLoading: isLoadingActiveSubscription || isLoadingUser,
    checkoutForm,
    setCheckoutForm,
    checkoutFlowType,
    isSeatQuantityReady,
    setIsSeatQuantityReady,
  };
};
