import {useMutation, useQuery} from 'react-query';
import { useMemo } from 'react';
import {
  AddSeatsRequest,
  AddSeatsResponse,
  ComputeStripePaymentInfoRequestV2,
  computeStripePaymentInfoV2, CreateStripeCheckoutSessionRequestV2,
  getWorkspaceSubscriptionsV2
} from 'seats/data/requests/workspaceBilling';
import {
  WorkspaceSubscriptionV2,
} from 'seats/model/workspaceBilling';
import {StripeCheckoutSession} from "model/workspaceBilling";
import {clientWorkspaceBilling} from "data/client";

export type UseWorkspaceSubscriptionsV2Props = {
  workspaceId?: string;
  status: 'ACTIVE' | 'CANCELED';
};
export const useWorkspaceSubscriptionsV2 = (
  {
    workspaceId,
    status,
  }: UseWorkspaceSubscriptionsV2Props,
  onSuccess?: (data?: WorkspaceSubscriptionV2[]) => void,
) => {
  return useQuery<WorkspaceSubscriptionV2[] | undefined>(
    [
      'space',
      workspaceId,
      'subscriptions',
      status,
    ],
    () =>
      getWorkspaceSubscriptionsV2(
        workspaceId!,
        status,
      ),
    {
      enabled: !!workspaceId,
      staleTime: 5000,
      refetchOnMount: false,
      onSuccess,
    },
  );
};

export type UseWorkspaceActiveSubscriptionV2Props = {
  workspaceId?: string;
};
export const useWorkspaceActiveSubscriptionV2 = (
  props: UseWorkspaceActiveSubscriptionV2Props,
) => {
  const { data, ...query } = useWorkspaceSubscriptionsV2({
    ...props,
    status: 'ACTIVE'
  });

  const active = useMemo(() => {
    let activeSub: WorkspaceSubscriptionV2 | undefined;

    if (data && data.length) {
      activeSub = data.find(
        (workspace) =>
          workspace.subscriptionStatus === 'ACTIVE' &&
          workspace.workspaceId === props.workspaceId,
      );
    }

    return activeSub;
  }, [data]);

  return {
    ...query,
    data: active,
  };
};

const generateComputeStripePaymentInfoPayloadKeyV2 = (
  data?: ComputeStripePaymentInfoRequestV2,
) =>
  data
    ? [
      data.purchaseType,
      data.country,
      data.billingInterval,
      data.workspacePlan,
      data.workspacePlan,
      data.seatPlans,
    ]
    : [];


export const useComputeStripePaymentInfoV2 = (
  workspaceId?: string,
  data?: ComputeStripePaymentInfoRequestV2,
  currentSeatQuantity: number = 0,
) => {
  const isEnabled = Boolean(workspaceId && data)
  return useQuery(
    [
      'computeStripePaymentInfoV2',
      workspaceId,
      currentSeatQuantity,
      ...(generateComputeStripePaymentInfoPayloadKeyV2(data)),
    ],
    () =>
      computeStripePaymentInfoV2(workspaceId as string, data as ComputeStripePaymentInfoRequestV2),
    {
      enabled: isEnabled,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  );
};

export const createStripeCheckoutSessionV2 = async (
  workspaceId: string,
  data: CreateStripeCheckoutSessionRequestV2,
): Promise<StripeCheckoutSession> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.post<StripeCheckoutSession>(
    `/workspace-billing/workspaces/${workspaceId}/stripe/checkout/v2/session`,
    data,
  );
  return response;
};


export const useCreateStripeCheckoutSessionV2 = () => {
  return useMutation(({
                        workspaceId, ...data
                      }: CreateStripeCheckoutSessionRequestV2 & {
    workspaceId: string
  }) => createStripeCheckoutSessionV2(workspaceId, data));
};


export const addSeats = async (
  workspaceId: string,
  subscriptionId: string,
  requestPayload: AddSeatsRequest
): Promise<AddSeatsResponse> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.post<AddSeatsResponse>(
    `/workspace-billing/workspaces/${workspaceId}/subscriptions/v2/${subscriptionId}/seats/v1`,
    requestPayload,
  );
  return response;
};

export const useAddSeats = () => {
  return useMutation(({
    workspaceId, subscriptionId, ...data
  }: AddSeatsRequest & {
    workspaceId: string,
    subscriptionId: string,
  }) => addSeats(workspaceId, subscriptionId, data));
};