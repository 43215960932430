import {
  BillingBusinessType,
  BillingInterval,
} from 'model/workspaceBilling';
import {WorkspaceSubscriptionV2} from "seats/model/workspaceBilling";
import {ComputeStripePaymentInfoRequestV2, PaidUserPlan} from "seats/data/requests/workspaceBilling";

const DEFAULT_COUNTRY = 'US';

export const getCountryV2 = (sub: WorkspaceSubscriptionV2): string =>
  sub?.additionalStripeInfo?.country || DEFAULT_COUNTRY;

export const getPurchaseTypeV2 = (
  sub: WorkspaceSubscriptionV2,
): BillingBusinessType =>
  sub?.additionalStripeInfo?.companyName ? 'B2B' : 'B2C';

export const getDefaultComputeParams = (
  billingInterval: BillingInterval,
  quantity: number,
  userPlan: PaidUserPlan,
  country?: string,
  purchaseType?: BillingBusinessType,
): ComputeStripePaymentInfoRequestV2 => {
  return {
    country: country || DEFAULT_COUNTRY,
    purchaseType: purchaseType || 'B2C',
    seatPlans: [{
      userPlan,
      quantity,
    }],
    billingInterval,
    previewProratedAmounts: false
  };
};

export const getComputeParamsForExistingSubscription = (
  activeSubscription: WorkspaceSubscriptionV2,
  quantity: number,
): ComputeStripePaymentInfoRequestV2 => {
  return {
    country: getCountryV2(activeSubscription) ?? DEFAULT_COUNTRY,
    purchaseType: getPurchaseTypeV2(activeSubscription),
    seatPlans: [{
      userPlan: activeSubscription.subscriptionPaymentPlans[0].userPlanType as PaidUserPlan,
      quantity,
    }],
    billingInterval: activeSubscription.billingInterval,
    previewProratedAmounts: true,
    workspacePlan:  activeSubscription.workspacePlan,
  };
};
