import {
  Button,
  Dialog,
  FlexContainer,
  Heading,
  Color,
} from '@scriptaddicts/yamm-ui-components';
import React, {FC} from 'react';
import {plurify} from 'utils/plurify';
import {
  LinksSection,
  Loading,
  Of, Progress, Progressbar,
  SeatsStatusContainer,
  SeatsStatusTextContainer, SeatsUsed, SeatsUsedContainer, StyledListItem
} from "seats/containers/Main/Workspaces/Billing/CurrentPlan/styles";
import {ManageSeatsProps} from 'seats/widgets/ManageSeats/types';
import {useConnect} from './connect';
import {Container} from './styles';

export const ManageSeats: FC<ManageSeatsProps> = ({
  show,
  onClose,
}) => {
  const {
    isLoading,
    isMobile,
    subscriptionSeat,
    onAddSeats,
    onRemoveUnusedSeats,
  } = useConnect( );

  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">Manage seats</Heading>
        </FlexContainer>
        <FlexContainer flex="vertical" gap="base">
          <SeatsStatusContainer>
            <SeatsStatusTextContainer>
              <SeatsUsedContainer>
                <Of>{`${subscriptionSeat.assignedSeats} of ${subscriptionSeat.totalSeats} `}</Of>
                <SeatsUsed>{plurify(subscriptionSeat.totalSeats, 'seat', 'seats')} used</SeatsUsed>
              </SeatsUsedContainer>
            </SeatsStatusTextContainer>
            <Loading>
              <Progressbar>
                <Progress style={{width: `${subscriptionSeat.getUsagePercentage()}%`}}/>
              </Progressbar>
            </Loading>
          </SeatsStatusContainer>
          {
            subscriptionSeat.hasAvailableSeats() && (
                                                   <LinksSection>
                                                     <StyledListItem onClick={onAddSeats}>
                                                       <Color variant="info">Add seats</Color>
                                                     </StyledListItem>
                                                     <StyledListItem onClick={onRemoveUnusedSeats}>
                                                       <Color variant="info">Remove unused seats</Color>
                                                     </StyledListItem>
                                                   </LinksSection>)
          }
        </FlexContainer>

        {
          !subscriptionSeat.hasAvailableSeats() && (
                                                  <FlexContainer flex="vertical" gap="base" style={{alignItems: 'flex-end'}}>
                                                    <Button
                                                      aria-label="Add seats"
                                                      variant="primary"
                                                      className="autoTagCatch"
                                                      onClick={onAddSeats}
                                                    >
                                                      Add seats
                                                    </Button>
                                                  </FlexContainer>
                                                )
        }
      </Container>
    </Dialog>
  );
};
