import styled from "styled-components";
import { Text } from '@scriptaddicts/yamm-ui-components';

export const DecreaseButton = styled.div`
    width: 24px;
    height: 24px;

    &.disabled {
        opacity: 0.4;
        fill: ${(props) => props.theme.colors.gray100};
    }
`;

export const IncreaseButton = styled.div`
    width: 24px;
    height: 24px;

    &.disabled {
        opacity: 0.4;
        fill: ${(props) => props.theme.colors.gray100};
    }
`;

export const SpinnerInput = styled.input`
    color: ${(props) => props.theme.colors.gray800};
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.225px;
    border: none;
    background: ${(props) => props.theme.colors.gray50};
    min-width: 1ch;
    max-width: 3ch;
    align-items: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;

    &:focus {
        border: none;
        outline: none;
    }
`;



export const NumberInputSpinnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;

export const NumberInputSpinnerBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const NumberInputSpinnerComponent = styled.div<{ isValid: boolean }>`
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.isValid ? props.theme.colors.gray200 : props.theme.colors.red500};
    background: ${(props) => props.theme.colors.gray50};
`;

export const NumberInputSpinnerValidation = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;

export const ValidationMessage = styled(Text)`
    color: ${(props) => props.theme.colors.red500};
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 20.8px */
    letter-spacing: -0.086px;
  `