import React, { FC } from 'react';
import { Color, Spinner, Text } from '@scriptaddicts/yamm-ui-components';
import { formatLongMonthDayYear } from 'utils/dates';
import {
  BillingCurrency,
  BillingInterval,
} from 'model/workspaceBilling';
import { formatPriceSignOnly } from 'utils/priceFormat';
import { StyledBillingCycleChangeContainer } from './styles';

const CYCLE_LABELS: { [key in BillingInterval]: [string, string] } = {
  MONTHLY: ['month', 'monthly'],
  YEARLY: ['year', 'yearly'],
};

export const RenewalText: FC<{
  date: Date;
  isRenewal: boolean;
  newCycle?: BillingInterval;
  newPrice?: number;
  currency?: BillingCurrency;
  onCancelCycleChange?: () => void;
}> = ({
  newCycle,
  date,
  isRenewal,
  newPrice,
  currency,
  onCancelCycleChange,
}) => {
  if (newCycle) {
    if (newPrice == null || !currency) {
      return (
        <StyledBillingCycleChangeContainer>
          <Spinner size={16} />
        </StyledBillingCycleChangeContainer>
      );
    }

    const cycleLabels = CYCLE_LABELS[newCycle];
    const priceLabel = formatPriceSignOnly(newPrice, currency, 2);

    return (
      <StyledBillingCycleChangeContainer>
        <Text type="paragraph" width="semiBold">
          <Color variant="success">
            <strong>Starting {formatLongMonthDayYear(date)}:</strong>
          </Color>
        </Text>
        <Text type="paragraph">
          <Color variant="success">
            {priceLabel} / {cycleLabels[0]}, billed {cycleLabels[1]}{' '}
          </Color>
          <button type="button" className="link" onClick={onCancelCycleChange}>
            (cancel this change)
          </button>
        </Text>
      </StyledBillingCycleChangeContainer>
    );
  }

  if (isRenewal) {
    return (
      <Text type="paragraph">Renews on {formatLongMonthDayYear(date)}</Text>
    );
  }

  return (
    <Text type="paragraph">
      <Color variant="error">Ends on {formatLongMonthDayYear(date)}</Color>
    </Text>
  );
};
