import {
  InputTag,
  useAutocompleteTagInput,
} from '@scriptaddicts/yamm-ui-components';
import {useMe} from 'data/hooks/useMe';

import {
  Dispatch,
  DispatchWithoutAction,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import {from, useMediaQuery} from 'styles/media';
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {useAssignUsersToSeats} from "seats/data/hooks/useAssignUsersToSeats";
import {useSuggestUser} from "data/hooks/useUsers";
import {AssignUsersResponse} from "seats/data/requests/workspaceBilling";
import {plurify} from "utils/plurify";

export const useConnect = ({
  onClose,
  onSuccess,
  workspaceId
}: {
  onClose: DispatchWithoutAction;
  onSuccess?: Dispatch<{ addedUsers: number }>;
  workspaceId: string | undefined;
}) => {
  const [userEmailTags, setUserEmailTags] = useState<InputTag[]>([]);

  const {
    tagInput: {tags: userEmailsToAssign, setTags: setUserEmailsToAssign, ...tagInput},
    autocomplete: {setValue, search, ...autocomplete},
  } = useAutocompleteTagInput({
                                autocomplete: {openOn: 2, timeout: 500},
                              });

  const {data: me} = useMe();
  const {subscriptionSeat, isSubscriptionSeatReady} = useSubscriptionSeat(workspaceId);
  const {data: userSuggestion} = useSuggestUser(
    {
      workspaceId,
      searchKeyword: search,
    },
    !!me && !me.isPersonal,
  );

  const [apiCallResult, setApiCallResult] = useState<AssignUsersResponse>({results: [], success: false});

  const {
    mutate: assignUserMutate,
    status: assignUserStatus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reset: assignUserReset,
  } = useAssignUsersToSeats(setApiCallResult);

  const errors = useMemo(
    () =>
      (apiCallResult?.results ?? []).reduce(
        (map, {userEmail, errorDetails}) => ({
          ...map,
          ...(userEmail && userEmailsToAssign.includes(userEmail)
            ? {
              [userEmail]: {
                tip: errorDetails,
                variant: errorDetails ? 'error' : undefined,
              },
            }
            : {}),
        }),
        [] as Record<string, any>,
      ),
    [apiCallResult, userEmailsToAssign],
  );

  // Validate on email addresses change
  useEffect(() => {
    setUserEmailTags(
      userEmailsToAssign.map((member) => errors[member] ? {text: member, ...errors[member]} : {text: member},
      ),
    );
  }, [userEmailsToAssign, errors]);

  const hasError = useMemo(
    () => {
      const invalidEmailsCount =
        Object.entries(errors).filter(([, value]) => value.variant !== undefined).length;

      let partialMessage;
      if (subscriptionSeat.availableSeats < userEmailTags.length) {
        partialMessage = `There are ${subscriptionSeat.availableSeats} available ${plurify(
          subscriptionSeat.availableSeats,
          'seat',
          'seats'
        )} in this space so you can add ${subscriptionSeat.availableSeats} ${plurify(
          subscriptionSeat.availableSeats,
          'user',
          'users'
        )}. To add more users you need to add more seats to the space first.`
      } else if (invalidEmailsCount > 0) {
        partialMessage = "We can't add some email addresses. Hover over them to know why."
      }
      return ({
        partial: partialMessage,
        all: !!userEmailTags.length && invalidEmailsCount === userEmailTags.length,
      });
    },
    [errors, userEmailTags, subscriptionSeat],
  );

  const assignCallback = useCallback(() => {
    const userEmails = userEmailsToAssign; // .filter((email) => !errors[email]);
    if (!userEmails.length || !workspaceId) {
      return;
    }
    assignUserMutate({
                       workspaceId,
                       params: {
                         userEmails
                       },
                     },);
  }, [assignUserMutate, workspaceId, userEmailsToAssign, errors]);

  const resetForm = useCallback(() => {
    setUserEmailsToAssign([]);
    setValue('');
  }, [setUserEmailsToAssign, setValue]);

  useEffect(() => {
    if (assignUserStatus === 'success') {
      if (hasError.partial === undefined && onSuccess) {
        onSuccess({addedUsers: userEmailTags.length});
        onClose();
        resetForm();
      }
    }
  }, [hasError.partial, assignUserStatus]);

  const isMobile = !useMediaQuery(from.tablet);


  return {
    isLoading: assignUserStatus === 'loading' || isSubscriptionSeatReady,
    resetForm,
    domain: me?.domain,
    search: {
      items: userSuggestion,
      ...autocomplete,
    },
    tags: {
      userEmailTags,
      hasError,
      ...tagInput,
    },
    assign: {
      onClick: assignCallback,
      count: userEmailsToAssign.length,
      disabled: !userEmailsToAssign.length && !search,
    },
    isMobile,
    availableSeats: subscriptionSeat.availableSeats,
  };
};
