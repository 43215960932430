import { SeatCheckoutFlowType } from "seats/data/context/CheckoutContext/types";

export type PathInfo = {
  title: string;
  suffix: string;
  goBackPath: string;
  test: (path: string) => boolean;
};

export const PathsMapping: {
  [key in SeatCheckoutFlowType]: () => PathInfo[];
} = {
  DOMAIN_PURCHASE: () => {
    const paths: PathInfo[] = [
      {
        title: 'Billing details',
        suffix: '/checkout/details',
        test: (path) => path.endsWith('/details'),
        goBackPath: '/space/billing',
      },
      {
        title: 'Billing information',
        suffix: '/checkout/information',
        test: (path) => path.endsWith('/information'),
        goBackPath: '/checkout/details',
      },
      {
        title: 'Checkout',
        suffix: 'summary',
        test: (path) => path.endsWith('/summary'),
        goBackPath: '/checkout/information',
      },
    ];
    return paths;
  },

  GMAIL_PURCHASE: () => {
    const paths: PathInfo[] = [
      {
        title: 'Billing information',
        suffix: '/checkout/information',
        test: (path) => path.endsWith('/information'),
        goBackPath: '/space/billing',
      },
      {
        title: 'Checkout',
        suffix: 'summary',
        test: (path) => path.endsWith('/summary'),
        goBackPath: '/checkout/information',
      },
    ];
    return paths;
  },

  ADDITIONAL_SEATS: () => {
    const paths: PathInfo[] = [
      {
        title: 'Add seats',
        suffix: '/checkout/add-seats',
        test: (path) => path.endsWith('/add-seats'),
        goBackPath: '/space/billing',
      }
    ];
    return paths;
  },

  EMPTY_FLOW: () => {
    const paths: PathInfo[] = [
    ];
    return paths;
  }
};
