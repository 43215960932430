import React from 'react';
import { ReactComponent as Dot } from 'assets/images/icons/dot.svg';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { Container, ItemGroup, ItemGroupItem, UserSettingsInfoDescriptionText, ItemIcon } from './styles';

const UserSettingsInfo = () => {
	const { user } = useWorkspaceContext();
	
	const adminInfoItems = [
		'Remove them from the Space',
	];
	const ownerInfoItems = [
		'Change their role',...adminInfoItems
	];
	const userSettingsInfoItems = user?.role === 'OWNER' ? ownerInfoItems : adminInfoItems;  
	const userSettingsInfo = userSettingsInfoItems
		.map(info => <ItemGroupItem key={info}><ItemIcon as={Dot} /><UserSettingsInfoDescriptionText>{info}</UserSettingsInfoDescriptionText></ItemGroupItem>);
  return (
    <Container>
		<UserSettingsInfoDescriptionText>Select member to:</UserSettingsInfoDescriptionText>
		<ItemGroup>{userSettingsInfo}</ItemGroup>
    </Container>
  );
};

export default UserSettingsInfo;
