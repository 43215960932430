import React from 'react';
import { Formik } from 'formik';
import { Alert } from '@scriptaddicts/yamm-ui-components';
import {MainContainerLoader} from "components/MainContainer";
import { useConnect } from 'seats/containers/Checkout/SeatsBillingInformation/connect';
import SeatCheckoutForm from 'seats/containers/Checkout/SeatsBillingInformation/CheckoutForm';
import CheckoutLayout from 'seats/containers/Checkout/Layout';
import SeatsBillingInformationSidebar from 'seats/containers/Checkout/SeatsBillingInformationSidebar';
import { StyledCard, StyledTitle, StyledTitleWrapper } from 'seats/containers/Checkout/common/styles';

const SeatsBillingInformation = () => {
  const {
    workspaceDetails,
    isLoading,
    checkoutForm,
    isEuropeanCountry,
    isBusinessPurchase,
    isSubmitting,
    submitError,
    isFetchingPaymentInfo,
    setIsBusinessPurchase,
    setCountry,
    validationSchema,
    onSubmit,

  } = useConnect();

  return (
    <Formik
      initialValues={checkoutForm}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <CheckoutLayout.Container>
        <CheckoutLayout.Main>
          {isLoading || !workspaceDetails ? (
            <MainContainerLoader />
          ) : (
            <>
              {submitError && (
                <Alert type="error" style={{ marginBottom: '20px' }}>
                  An error occurred. Please try again.
                </Alert>
              )}
              <StyledTitleWrapper>
                <StyledTitle>Upgrading {workspaceDetails.name}</StyledTitle>
              </StyledTitleWrapper>
              <StyledCard variant="white" flex="vertical" padding="big">
                <SeatCheckoutForm
                  defaultCountry={checkoutForm.country}
                  isEuropeanCountry={isEuropeanCountry}
                  isBusinessPurchase={isBusinessPurchase}
                  handleCountryChange={setCountry}
                  handleTypeOfBusinessChange={setIsBusinessPurchase}
                  defaultVATNumber={checkoutForm.vatNumber}
                  defaultCompanyName={checkoutForm.companyName}
                  disabled={isSubmitting}
                />
              </StyledCard>
            </>
          )}
        </CheckoutLayout.Main>
        <CheckoutLayout.Sidebar>
          <SeatsBillingInformationSidebar disabled={isFetchingPaymentInfo || isLoading} isSubmitting={isSubmitting}/>
        </CheckoutLayout.Sidebar>
      </CheckoutLayout.Container>
    </Formik>
  );
};

export default SeatsBillingInformation;
