import styled from 'styled-components';
import { from } from 'styles/media';

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const InfoContainer = styled.div`
  padding: 0px 24px;

  ${from.tablet} {
    padding: 0px;
  }
`;

export const TableContainer = styled.div`
  margin-top: 10px;
  ${from.tablet} {
    margin-top: 8px;
  }
`;

export const ManageSeatsText = styled.span`
  color: ${(props) => props.theme.colors.gray800};
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const ManageSeatsLink = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue700};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.106px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;
export const ManageSeatsContainer = styled.div`
`;

export const SeatsStatusTextInnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;
