import React, {useRef} from 'react';
import {BillingInterval} from '../../../../../../../model/workspaceBilling';
import {
    StyledFeatureText,
    PurchasePlanCardContainer,
    PurchasePlanCardHeaderContainer,
    PurchasePlanCardPriceContainer,
    PurchasePlanCardFeatureTitle,
    PurchasePlanCardFeaturesContainer,
    PurchasePlanCardHeader,
    PurchasePlanCardHeaderText,
    PurchasePlanCardHeaderSubText,
    PurchasePlanCardMonthlyPrice,
    PurchasePlanCardMonthlyPriceBigger,
    PurchasePlanCardAnnuallyPriceBigger,
    PurchasePlanCardAnnuallyPrice,
    PurchasePlanCardUpgradeButtonContainer,
    PurchasePlanCardUpgradeButtonText,
} from './styles';
import {formatPriceSignOnly} from '../../../../../../../utils/priceFormat';
import {BillingPlanInformation} from '../data/billingPlans';
import {useSyncRefHeight} from '../../../../../../../utils/useSyncHeight';
import {from, useMediaQuery} from '../../../../../../../styles/media';
import {PlanPrice} from '../../../../../../../model/paymentPlans';
import CompleteFeatureList from "../../../../../../../containers/Main/Workspaces/Billing/CompleteFeatureList";
import {getRecipientsQuotaPerDay} from "../../../../../../../utils/workspace";
import {Plan} from "../../../../../../../model/plan";

type ActionButtonProps = {
    isCurrentPlan: boolean;
    isDowngrade: boolean;
    isLegacy: boolean;
    onClick: () => void;
};

export type PurchasePlanCardProps = BillingPlanInformation &
    ActionButtonProps & {
    interval: BillingInterval;
    price: PlanPrice;
    isGmailUser: boolean;
};

const getPaidUserPlan = (isGmail: boolean): Plan =>
  isGmail ? 'PRO_PERSONAL' : 'PRO';

const PurchasePlanCard = ({
                            name,
                            description,
                            price,
                            currency,
                            common_features,
                            domain_user_features,
                            gmail_user_features,
                            onClick,
                            isGmailUser,
                          }: PurchasePlanCardProps) => {
    const featuresRef = useRef(null);
    const descriptionRef = useRef(null);
    const supportFeaturesRef = useRef(null);
    const isMobile = !useMediaQuery(from.tablet);

    const features = isGmailUser ? [...common_features, ...gmail_user_features] : [...common_features, ...domain_user_features, ...gmail_user_features];


    useSyncRefHeight(
        [
            ['purchase-plan-card-description', descriptionRef],
            ['purchase-plan-card-features', featuresRef],
            ['purchase-plan-card-support-features', supportFeaturesRef],
        ],
        !isMobile,
        [isMobile],
    );

  const recipientsQuotaPerDay = getRecipientsQuotaPerDay(getPaidUserPlan(isGmailUser))?.toLocaleString("en-US");
  return (
        <PurchasePlanCardContainer>
            <PurchasePlanCardHeaderContainer>
                <PurchasePlanCardHeader>
                    <PurchasePlanCardHeaderText>{name}</PurchasePlanCardHeaderText>
                    <PurchasePlanCardHeaderSubText>{description}</PurchasePlanCardHeaderSubText>
                </PurchasePlanCardHeader>

                <PurchasePlanCardUpgradeButtonContainer
                    onClick={onClick}
                    aria-label="Upgrade"
                    variant="primary"
                    size="extraLarge"
                    data-log-event='go_to_checkout'
                    data-log-event-data={JSON.stringify({
                        displayName: 'Upgrade',
                    })}
                >
                    <PurchasePlanCardUpgradeButtonText>Upgrade</PurchasePlanCardUpgradeButtonText>
                </PurchasePlanCardUpgradeButtonContainer>
            </PurchasePlanCardHeaderContainer>
            <PurchasePlanCardPriceContainer>
                <PurchasePlanCardMonthlyPrice>
                  <PurchasePlanCardMonthlyPriceBigger>
                    {formatPriceSignOnly(price.year / 12, currency, 2)}
                  </PurchasePlanCardMonthlyPriceBigger>
                  {' '}/ seat / month
                </PurchasePlanCardMonthlyPrice>
                <PurchasePlanCardAnnuallyPrice>
                    <PurchasePlanCardAnnuallyPriceBigger> {formatPriceSignOnly(price.year, currency)}
                    </PurchasePlanCardAnnuallyPriceBigger>
                    {' '}billed annually
                </PurchasePlanCardAnnuallyPrice>
            </PurchasePlanCardPriceContainer>
            <PurchasePlanCardFeatureTitle>
                Everything in free, plus:
            </PurchasePlanCardFeatureTitle>
            <PurchasePlanCardFeaturesContainer>
                <StyledFeatureText key="upTo">
                  <span style={{fontWeight: 700}}>Up to {recipientsQuotaPerDay}</span> recipients / user / day
                </StyledFeatureText>
                {features.map((feature) => (
                    <StyledFeatureText key={feature}>{feature}</StyledFeatureText>
                ))}

            </PurchasePlanCardFeaturesContainer>
            <CompleteFeatureList isLegacyPlanType={false}/>
        </PurchasePlanCardContainer>
    );
};

export default PurchasePlanCard;
