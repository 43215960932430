import React, { FC, Fragment } from 'react';
import {
  StyledContainer,
  ActiveStep,
  FutureStep,
  StepsSeparator,
} from './styles';
import { from, useMediaQuery } from '../../../../../../styles/media';

export type CheckoutStepsProps = {
  steps: string[];
  active: number;
};
const CheckoutSteps: FC<CheckoutStepsProps> = ({ steps, active }) => {
  const isMobile = !useMediaQuery(from.tablet);

  if (isMobile) {
    return (
      <StyledContainer>
        <ActiveStep>{steps[active]}</ActiveStep>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      {steps.map((step, index) => {
        const TextComponent = index === active ? ActiveStep : FutureStep;
        return (
          <Fragment key={step}>
            <TextComponent key={step}>
              {steps.length > 1 ? `${index + 1}. ` : ''}{step}
            </TextComponent>
            {index < steps.length - 1 && <StepsSeparator />}
          </Fragment>
        );
      })}
    </StyledContainer>
  );
};

export default CheckoutSteps;
