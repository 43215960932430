import styled from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const AreYouBuying = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 160%;
  font-weight: 700;
`;
export const FormsradioIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
export const OptionRadio = styled.div`
  position: relative;
  letter-spacing: -0.01em;
  line-height: 160%;
`;
export const RadioOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
export const Options = styled.div`
  border-radius: 5px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
`;
export const OptionsFormContainer = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #c8c4be;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #474340;
  font-family: Inter;
`;

export const TeamSeatQuantitySpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const TeamSeatQuantityMessage = styled(Text).attrs({
  type: 'paragraph',
})`
  align-self: stretch;
  color: ${(props) => props.theme.colors.gray600};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.092px;
`;

export const ForMyselfQuantityMessage = styled(Text).attrs({
  type: 'paragraph',
})`
  align-self: stretch;
  color: ${(props) => props.theme.colors.gray600};
  font-variant-numeric: lining-nums tabular-nums;

  /* Desktop/Small */
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.092px;
`;

 export const BoldMessage = styled.span`
  color: ${(props) => props.theme.colors.gray700};
  font-weight: 700;
 `
