import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { createFilter, createSort } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';
import { WorkspaceMemberWithSeat, WorkspaceMemberWithSeatParams } from 'seats/model/WorkspaceMemberWithSeats';
import {getWorkspaceUsers} from "../requests/workspaceUsers";

type UseWorkspaceMembersOptions = {
  refetchOnWindowFocus: boolean;
};
export const useWorkspaceMembersWithSeat = (
  workspaceId?: string,
  params?: WorkspaceMemberWithSeatParams,
  options?: UseWorkspaceMembersOptions,
) => {
  const { isEnabled, queryParams } = useMemo(
    () => ({
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter(params?.filters),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery(
    ['space', workspaceId, 'members', queryParams],
    () => getWorkspaceUsers(workspaceId!, queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: true,
      staleTime: 1000,
      refetchOnWindowFocus: options ? options.refetchOnWindowFocus : true,
    },
  );

  const sortedData = useMemo(
    () =>
      data && !queryParams.sortBy
        ? [
            ...data
              .filter(({ status }) => status !== 'PENDING')
              .sort((a, b) => a.createTimestamp - b.createTimestamp),
            ...data
              .filter(({ status }) => status === 'PENDING')
              .sort((a, b) => a.createTimestamp - b.createTimestamp),
          ]
        : data,
    [data, queryParams.sortBy],
  );

  return {
    data: useDefaultArray<WorkspaceMemberWithSeat>(sortedData),
    ...rest,
  };
};
