import styled from 'styled-components';
import { Spinner } from '@scriptaddicts/yamm-ui-components';
import ListItem from 'components/ListItem';
import { from } from 'styles/media';
import { ReactComponent as PayPalSvg } from 'assets/images/icons/paypal-billing-icon.svg';

export const StyledPlanContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const LinksSection = styled.div`
`;

export const StyledListItem = styled(ListItem)`
  cursor: pointer;
`;

export const LoadingSpinner = styled(Spinner).attrs({
  size: 50,
})`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 200px);
  --spinner-color: ${({ theme }) => theme.colors.blue500};
`;

export const StyledBillingCycleChangeContainer = styled.div`
  margin-top: 16px;

  button.link {
    padding: 0;
    color: ${({ theme }) => theme.colors.blue700};
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
`;

export const PayPalIcon = styled(PayPalSvg)`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 42px;
  height: 42px;
`;

export const SeatsUsedContainer = styled.div`
  position: relative;
  letter-spacing: -0.01em;
  line-height: 125%;
`;

export const Of = styled.span`
  font-weight: 600;
`;

export const SeatsUsed = styled.span`
  color: ${(props) => props.theme.colors.gray800};
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;`
;

export const SeatsStatusTextContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Progress = styled.div`
  display: flex;
  height: 12px;
  align-items: flex-start;
  background: var(
    --gradient-lt-r-blue-500400,
    linear-gradient(90deg, #0075e0 0%, #3393ee 100%)
  );
`;

export const Progressbar = styled.div`
  height: 12px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.gray100};
  overflow: hidden;
`;

export const Loading = styled.div`
  display: flex;
  height: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 100px;
`;

export const SeatsStatusContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 15px;
`;

export const StyledFeatureContainer = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
export const StyledFeatureItem = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: -0.21px;
`;

export const RightCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
`;

export const RightCardTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RightCardSubTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-variant-numeric: lining-nums tabular-nums;

  /* Desktop/Small */
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.092px;
`;
