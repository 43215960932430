import {StripePlanV2} from "seats/model/workspaceBilling";
import {SeatCheckoutFormValues} from "seats/data/context/CheckoutContext/types";
import {CreateStripeCheckoutSessionRequestV2} from "seats/data/requests/workspaceBilling";

export type MapCheckoutFormToCheckoutRequestPropsV2 = {
  workspaceId: string;
  checkoutForm: SeatCheckoutFormValues;
  stripePlans: StripePlanV2[];
};

export const mapCheckoutFormToCheckoutRequestV2 = ({
                                                   workspaceId,
                                                   checkoutForm,
                                                   stripePlans,
                                                 }: MapCheckoutFormToCheckoutRequestPropsV2): CreateStripeCheckoutSessionRequestV2 & {
  workspaceId: string;
} => {
  return {
    workspaceId,
    addressInfo: {
      country: checkoutForm.country,
      state: checkoutForm.state,
      city: checkoutForm.city,
      postalCode: checkoutForm.code,
      street: checkoutForm.street,
    },
    companyName: checkoutForm.companyName,
    customerName: checkoutForm.name,
    isB2BTransaction: checkoutForm.isBusinessPurchase,
    vatNumber: checkoutForm.isBusinessPurchase ? checkoutForm.vatNumber : undefined,
    goBackUrl: window.location.hostname === 'localhost' ? window.location.href : window.location.href.replace('http:', 'https:'),
    userName: checkoutForm.name,
    stripePlans,
  };
};