import {useMemo} from "react";
import {useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";

class SubscriptionSeat {
  assignedSeats: number;

  totalSeats: number;

  availableSeats: number;

  constructor(assignedSeats: number = 0, totalSeats: number = 0, availableSeats: number = 0) {
    this.assignedSeats = assignedSeats;
    this.totalSeats = totalSeats;
    this.availableSeats = availableSeats;
  }

  hasAvailableSeats(): boolean {
    return this.availableSeats > 0;
  }

  getUsagePercentage(): number {
    const toReturn = this.totalSeats > 0 ? (this.assignedSeats / this.totalSeats) * 100 : 0;
    return toReturn;
  }

  getSeatSummary(): string {
    return `Assigned: ${this.assignedSeats}, Total: ${this.totalSeats}, Available: ${this.availableSeats}`;
  }
}

const useSubscriptionSeat = (workspaceId: string | undefined) => {
  const {data: activeSubscription, isLoading: isSubscriptionSeatReady} = useWorkspaceActiveSubscriptionV2({
    workspaceId,
  });

  const subscriptionSeat = useMemo(() => {
    const assigned = activeSubscription?.subscriptionPaymentPlans[0]?.assignedSeats ?? 0;
    const total = activeSubscription?.subscriptionPaymentPlans[0]?.totalSeats ?? 0;
    const available = activeSubscription?.subscriptionPaymentPlans[0]?.availableSeats ?? 0;
    return new SubscriptionSeat(assigned, total, available);
  }, [activeSubscription]);
  return {
    subscriptionSeat,
    isSubscriptionSeatReady,
  };
};

export default useSubscriptionSeat;