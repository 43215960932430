import { useDisclosure } from '@scriptaddicts/yamm-ui-components';
import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import { useEffect, useMemo, useState } from 'react';
import { from, useMediaQuery } from 'styles/media';
import { useLocation } from 'react-router-dom';
import { logDisplayModal } from 'utils/userJourneyLogging';
import {isSupportGivenSubscription} from "utils/subscriptions";
import {Links} from "data/links";
import {FETCH_WORKSPACE_USERS_MAX} from "data/constants";
import {useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";
import { WorkspaceMemberWithSeat } from 'seats/model/WorkspaceMemberWithSeats';
import {SortingRule} from "react-table";
import { TableStateManagerSettings } from 'components/ResponsiveTable/types';
import { getColumns } from './table/columns';
import useManageUser from './ManageUser/useManageUser';
import useSubscriptionSeat from "../../../../data/hooks/useSubscriptionSeat";
import {useWorkspaceMembersWithSeat} from "../../../../data/hooks/useWorkspaceMembersWithSeat";
import {convertSortToParameters} from "../../../../../utils/parameters";


const countMembersStats = (members: WorkspaceMemberWithSeat[]) => {
  const temp = {
    total: members.length,
    paid: 0,
    gmailPaid: 0,
    free: 0,
    disabled: 0,
  };
  members.forEach((member) => {
    if (member.plan === 'PRO') {
      temp.paid += 1;
    }
    if (member.plan === 'PRO_PERSONAL') {
      temp.gmailPaid += 1;
    }
    if (member.plan === 'FREE' && member.status !== 'DISABLED') {
      temp.free += 1;
    }
    if (member.status === 'DISABLED') {
      temp.disabled += 1;
    }
  });
  return temp;
};

export const useConnect = () => {
  const { data: me, status: meStatus } = useMe({ keepPreviousData: true });
  const {
    id,
    details,
    user,
    isLoading,
    restrictions,
    userAlerts,
    setUserAlerts,
  } = useWorkspaceContext();

  const [tableSettings, setTableSettings] = useState<
      TableStateManagerSettings
  >({
    idField: 'email',
  });

  const handleTableSettingsUpdate = (sortBy: SortingRule<object>[]) => {
    setTableSettings((prev: TableStateManagerSettings) => ({
      ...prev,
      sortBy: convertSortToParameters(sortBy, {
        email: 'userEmail',
        name: 'userName',
        role: 'userRole',
        status: 'seatType',
      }),
      manualSortBy: true,
    }));
  };

  const {
    state: { selection },
    manager: tableStateManager,
  } = useTableStateManager(tableSettings);

  const { data: members, status: membersStatus } = useWorkspaceMembersWithSeat(id, {
    filters: {
      userStatus: ['ACTIVE'],
    },
    sort: tableSettings.sortBy,
    limit: FETCH_WORKSPACE_USERS_MAX,
  });

  const { data: activeSubscription } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id
  });

  const {subscriptionSeat, isSubscriptionSeatReady} = useSubscriptionSeat(id);

  const canManageUsers = useMemo(() => {
    return user!.role === 'OWNER' || user!.role === 'ADMIN';
  }, [user]);

  const canManageBilling = useMemo(() => {
    return user!.role === 'OWNER';
  }, [user]);

  const counts = useMemo(() => countMembersStats(members), [members]);

  const limits = useMemo(() => {
    return {
      freeMember: details?.features.FREE.allowedUserCount ?? 0,
      domain: details?.features.FREE.hasMultiDomain ? undefined : me?.domain,
      paidMember: details?.features.PRO.allowedUserCount ?? 0,
    };
  }, [me, details]);

  const availability = useMemo(() => {
    const add = (() => {
      if (
        details?.legacyType !== 'TEAM' &&
        details?.legacyType !== 'UNLIMITED'
      ) {
        return 'TYPE';
      }
      if (limits.paidMember !== -1 && counts.paid >= limits.paidMember) {
        return 'LIMIT';
      }

      return undefined;
    })();

    return { add };
  }, [details, limits, counts]);

  const isMobile = !useMediaQuery(from.tablet);
  const columns = useMemo(
    () =>
      getColumns({
        isMobile,
        domain: limits.domain,
      }),
    [isMobile, limits, availability, activeSubscription],
  );

  const { pathname, search } = useLocation();
  const addSeatsDisclosure = useDisclosure({});
  const manage = useManageUser({ members, ...selection });

  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setUserAlerts({});
    };
  }, [id]);

  const isSupportSubscription =
    activeSubscription && isSupportGivenSubscription(activeSubscription);

  const onContactUs = () => {
    window.open(Links.CONTACT_US, '_blank');
  };

  return {
    subscriptionSeat,
    id,
    name: details?.name,
    isLoading:
      isLoading || meStatus === 'loading' || membersStatus === 'loading' || isSubscriptionSeatReady,
    isBusy,
    promote: details?.plan === 'FREE' && user?.role === 'OWNER',
    counts,
    members,
    columns,
    tableStateManager,
    handleTableSettingsUpdate,
    isSupportSubscription,
    canManageUsers,
    canManageBilling,
    addUser: {
      ...addSeatsDisclosure,
      setUserAlerts,
      feature: details?.plan !== 'LEGACY' && !isSupportSubscription,
      restricted: !!restrictions?.INVITATION,
      limit: limits.freeMember,
      onOpen: () => {
        addSeatsDisclosure.onOpen();
        setTimeout(() =>
          logDisplayModal('send_invitation', pathname, search, id),
        );
      },
    },
    addSeats: {
      ...addSeatsDisclosure,
    },
    manage: {
      ...manage,
      setUserAlerts,
      setBusy: setIsBusy,
      limit: {
        domain: limits.domain,
        freeUsersMax: limits.freeMember,
      },
    },
    userAlerts,
    onContactUs,
  };
};
