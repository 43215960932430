import {client, clientWorkspaceBilling} from "data/client";
import {
  ComputeStripePaymentInfoV2, StripePlanV2, WorkspaceSubscriptionBaseV2, WorkspaceSubscriptionV2
} from 'seats/model/workspaceBilling';
import {
  BillingAddressInfo, BillingBusinessType, BillingInterval, BillingWorkspacePlan,
} from "model/workspaceBilling";

  export type WorkspaceSubscriptionResponseV2 = WorkspaceSubscriptionBaseV2 & {
	subscriptionTimestamp: string;
	startTimestamp: string;
	endTimestamp: string;
	createTimestamp: string;
	lastUpdateTimestamp?: string | null;
  };

export type PaidUserPlan = 'PRO' | 'PRO_PERSONAL';

export type SeatPlan = {
  userPlan: PaidUserPlan;
  quantity: number;
};

  
const mapWorkspaceSubscriptionResponseToEntityV2 = (
  subscriptionResponse: WorkspaceSubscriptionResponseV2
): WorkspaceSubscriptionV2 => ({
  ...subscriptionResponse,
  subscriptionTimestamp: parseInt(
    subscriptionResponse.subscriptionTimestamp,
    10
  ),
  startTimestamp: parseInt(subscriptionResponse.startTimestamp, 10),
  endTimestamp: parseInt(subscriptionResponse.endTimestamp, 10),
  createTimestamp: parseInt(subscriptionResponse.createTimestamp, 10),
  lastUpdateTimestamp: subscriptionResponse.lastUpdateTimestamp
    ? parseInt(subscriptionResponse.lastUpdateTimestamp, 10)
    : null,
});

export async function getWorkspaceSubscriptionsV2(
  workspaceId: string,
  status: 'ACTIVE' | 'CANCELED'
): Promise<WorkspaceSubscriptionV2[] | undefined> {
  const { data } = await client.get<{
    items?: WorkspaceSubscriptionResponseV2[];
  }>(`/workspace-billing/workspaces/${workspaceId}/subscriptions/v2`, {
    params: {
      status,
    },
    baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}`,
  });
  if (data.items) {
    return data.items.map(mapWorkspaceSubscriptionResponseToEntityV2);
  }
  return data.items;
}

export interface ComputeStripePaymentInfoRequestV2 {
  country?: string;
  purchaseType?: BillingBusinessType;
  workspacePlan?: BillingWorkspacePlan;
  seatPlans: SeatPlan[];
  previewProratedAmounts?: boolean;
  billingInterval?: BillingInterval;
}

export const computeStripePaymentInfoV2 = async (
  workspaceId: string,
  data: ComputeStripePaymentInfoRequestV2
): Promise<ComputeStripePaymentInfoV2> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.post<ComputeStripePaymentInfoV2>(
    `/workspace-billing/workspaces/${workspaceId}/stripe/checkout/v2/info`,
    data,
  );
  return response;
};

export type CreateStripeCheckoutSessionRequestV2 = {
  userName: string;
  vatNumber?: string;
  isB2BTransaction: boolean;
  companyName?: string;
  customerName?: string;
  addressInfo: BillingAddressInfo;
  stripePlans: StripePlanV2[];
  goBackUrl: string;
};

export type ApiGenericError = {
  errorCode: string;
  errorMessage: string;
  additionalInformation: string;
}

export type AddSeatsRequest = {
  useProratedAmounts: boolean;
  seatPlans: SeatPlan[];
}

export type AddSeatsResponse = {
  paymentPending: boolean;
  paymentFailed: boolean;
  errors: ApiGenericError[];
};

export type AssignUsersRequest = {
  userEmails:  string[];
}

export type AssignUserResult = {
  userEmail: string;
  isValid: boolean;
  errorDetails: string;}

export type AssignUsersResponse = {
  results:  AssignUserResult[];
  success: boolean;
}







