import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {postAssignUsers} from "seats/data/requests/workspaceAssignUsers";
import {BackendError} from "model/error";
import {AssignUsersRequest, AssignUsersResponse} from "seats/data/requests/workspaceBilling";
import {queryClient} from "data/queryClient";

export const useAssignUsersToSeats = (setApiCallResult: (value: (((prevState: AssignUsersResponse) => AssignUsersResponse) | AssignUsersResponse)) => void) => {
  return useMutation<
    AssignUsersResponse,
    AxiosError<BackendError>,
    { workspaceId: string; params: AssignUsersRequest } // Make these required
  >(
    async ({workspaceId, params}) => {
      return postAssignUsers({workspaceId, params});
    },
    {
      onSuccess: async (_result, {workspaceId}) => {
        setApiCallResult(_result);
        queryClient.invalidateQueries([
                                        'space',
                                        workspaceId,
                                        'subscriptions',
                                      ]);
        queryClient.invalidateQueries([
                                        'space',
                                        workspaceId,
                                        'members',
                                      ])

      }
    }
  );
};