import { DispatchWithoutAction, useEffect, useMemo, useState } from 'react';
import { WorkspaceMemberWithSeat } from 'seats/model/WorkspaceMemberWithSeats';

export default function useManageUser({
  members,
  selected,
  resetSelected,
}: {
  members: WorkspaceMemberWithSeat[];
  selected: string[];
  resetSelected: DispatchWithoutAction;
}) {
  const [data, setData] = useState<WorkspaceMemberWithSeat[]>([]);

  useEffect(() => {
    let key: number | undefined;
    if (selected.length > 0 && members.length > 0) {
      setData(members.filter(({ email }) => selected.includes(email)));
    } else {
      key = setTimeout(() => {
        setData(members.filter(({ email }) => selected.includes(email)));
      }, 300);
    }

    return () => {
      if (key) clearTimeout(key);
    };
  }, [members, selected]);

  const show = useMemo(
    () =>
      !!members.length && members.some(({ email }) => selected.includes(email)),
    [members, selected],
  );

  return {
    selected: data,
    allUsers: members,
    show,
    onClose: resetSelected,
  };
}
