import styled, { css } from 'styled-components';
import { from } from '../../../../styles/media';

export const StyledContainer = styled.div`
  display: block;

  ${from.tablet} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const StyledLayoutGrid = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.gray50};
  flex: 1;

  ${from.tablet} {
    flex-direction: row;
  }
`;

export const CheckoutMainContainer = styled.div`
  padding: 20px 0;

  ${from.tablet} {
    padding: 50px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentMain = styled.div`
  ${from.tablet} {
    min-width: 700px;
    max-width: 700px;
  }
`;

export const StyledSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: sticky;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;

  ${({ theme }) => css`
    ${from.tablet} {
      padding: 50px;
      width: 400px;
      min-width: 400px;
      box-shadow: inset 1px 0 0 ${theme.colors.gray200};
      border-top: none;
      position: relative;
    }

    border-top: 1px solid ${theme.colors.gray200};
    background: ${theme.colors.white};
    box-shadow: 0px -5px 15px -3px rgba(0, 0, 0, 0.05),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 10px 15px -3px rgba(0, 0, 0, 0.05);
  `}
`;

export const StyledTopBarContainer = styled.div`
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.blue1000};

  height: 56px;
  padding: 0 8px;
  display: grid;
  grid-template-columns: 90px 1fr 90px;

  ${from.tablet} {
    height: 72px;
    padding: 0 32px;
  }
`;
