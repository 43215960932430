import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import { useChangeActiveWorkspace } from 'data/hooks/useWorkspace';
import { useCallback } from 'react';
import { useWorkspaceActiveSubscriptionV2 } from 'seats/data/hooks/useSeatsWorkspaceBilling';
import { isSupportGivenSubscription } from 'utils/subscriptions';


export const useConnect = () => {
  const {
    id,
    details,
    otherWorkspaces,
    isLoading,
    isPreviousWorkspace,
  } = useWorkspaceContext();
  const { data: user } = useMe();
  const { 
    data: subscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id,
  });

  const { changeWorkspace } = useChangeActiveWorkspace();

  const onChange = useCallback(
    (workspaceId?: string) => {
      if (!workspaceId || workspaceId === id) return;
      changeWorkspace(workspaceId);
    },
    [id, changeWorkspace],
  );

  const seatCount = subscription?  subscription?.subscriptionPaymentPlans[0]?.totalSeats : details?.memberCount;
  return {
    isLoading: isLoading || isPreviousWorkspace || isLoadingActiveSubscription,
    id,
    name: details?.name,
    plan: details?.plan,
    legacyType: details?.legacyType,
    seatCount,
    workspaces: otherWorkspaces,
    isSupportSub: !!subscription && isSupportGivenSubscription(subscription),
    onChange,
    addonWorkspaceId: user?.addonWorkspaceId,
  };
};
