import { Color, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { plurify } from 'utils/plurify';
import { PageRestrictions } from 'data/hooks/restrictions/useRestrictions';
import CompleteFeatureList from 'containers/Main/Workspaces/Billing/CompleteFeatureList';
import {
  ComputedBillingPurchasePlansItemDivider,
  ComputedBillingPurchasePlansItemDividerLine,
} from 'seats/containers/Checkout/common/styles';
import { getRecipientsQuotaPerDay } from 'utils/workspace';
import {
  StyledListItem,
  LinksSection,
  SeatsUsedContainer,
  SeatsStatusTextContainer,
  SeatsStatusContainer,
  Of,
  Loading,
  Progress,
  Progressbar,
  SeatsUsed,
  StyledFeatureContainer,
  StyledFeatureItem,
} from './styles';

export type PlanSeatDetailsProps = {
  assignedSeats: number;
  totalSeats: number;
  workspaceUrlPrefix: string;
  pageRestrictions: PageRestrictions;
  isGmailUser: boolean | undefined;
};
const PlanSeatDetails = ({
  assignedSeats,
  totalSeats,
  workspaceUrlPrefix,
  pageRestrictions,
  isGmailUser,
}: PlanSeatDetailsProps) => {
  const { push } = useHistory();

  const onAddSeats = () => {
    push(`${workspaceUrlPrefix}/checkout/add-seats`);
  };

  const onRemoveUnusedSeats = () => {
    // TODO: Implement
  };

  const onManageUsers = () => {
    push(`${workspaceUrlPrefix}/space/users`);
  };

  const isUserPageRestricted =
    pageRestrictions.ready && pageRestrictions.userPage;
    const features = isGmailUser ? [
      '•   All basic features included',
      '•   Scheduling',
      `•   Up to ${getRecipientsQuotaPerDay('PRO_PERSONAL')} recipients / day`,
      '•   Priority support',
    ] :
    [
      '•   All basic features included',
      '•   User management',
      '•   Scheduling',
      `•   Up to ${getRecipientsQuotaPerDay('PRO')?.toLocaleString("en-US")} recipients / day`,
      '•   Priority support',
    ];
  const assignedSeatsPercentage = `${((assignedSeats / totalSeats) * 100).toFixed(0)}%`;
  return (
    <>
      <StyledFeatureContainer>{features.map((feature) => (
        <StyledFeatureItem key={feature}>
          <Text size="base">{feature}</Text>
          </StyledFeatureItem>
      ))}</StyledFeatureContainer>
      <CompleteFeatureList isLegacyPlanType={false} />
      {
        !isGmailUser && (
           <>
             <ComputedBillingPurchasePlansItemDivider>
               <ComputedBillingPurchasePlansItemDividerLine/>
             </ComputedBillingPurchasePlansItemDivider>
             <SeatsStatusContainer>
               <SeatsStatusTextContainer>
                 <SeatsUsedContainer>
                   <Of>{`${assignedSeats} of ${totalSeats} `}</Of>
                   <SeatsUsed>{plurify(totalSeats, 'seat', 'seats')} used</SeatsUsed>
                 </SeatsUsedContainer>
               </SeatsStatusTextContainer>
               <Loading>
                 <Progressbar>
                   <Progress style={{width: assignedSeatsPercentage}}/>
                 </Progressbar>
               </Loading>
             </SeatsStatusContainer>
             <LinksSection>
               <StyledListItem onClick={onAddSeats}>
                 <Color variant="info">Add seats</Color>
               </StyledListItem>
               <StyledListItem onClick={onRemoveUnusedSeats}>
                 <Color variant="info">Remove unused seats</Color>
               </StyledListItem>
               {!isUserPageRestricted && (
                 <StyledListItem onClick={onManageUsers}>
                   <Color variant="info">Manage users</Color>
                 </StyledListItem>
               )}
             </LinksSection>
           </>
       )
      }
    </>
  );
};

export default PlanSeatDetails;
