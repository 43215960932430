import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { SeatCheckoutFlowType } from 'seats/data/context/CheckoutContext/types';
import { useWorkspaceContext } from '../../../../../data/context/WorkspaceContext';
import { PathsMapping } from './topBarPathMapping';
import { useSeatCheckoutContext } from '../../../../data/context/CheckoutContext';

const getActiveIndex = (
  path: string,
  checkoutFlowType: SeatCheckoutFlowType,
): number => {
  const paths = PathsMapping[checkoutFlowType]();
  const index = paths.findIndex((element) => element.test(path));
  if (index < 0 || index >= paths.length) {
    return 0;
  }
  return index;
};

export const useConnect = () => {
  const { isLoading, urlPrefix } = useWorkspaceContext();
  const { push } = useHistory();
  const { checkoutFlowType } = useSeatCheckoutContext();

  const { location } = useHistory();

  const topBarInfo = useMemo<{ activeIndex: number; steps: string[] }>(() => {
    const steps = PathsMapping[checkoutFlowType]();
    return {
      steps: steps.map((element) => element.title),
      activeIndex: getActiveIndex(
        location.pathname,
        checkoutFlowType,
      ),
    };
  }, [checkoutFlowType]);

  const goBack = () => {
    const {goBackPath} = PathsMapping[checkoutFlowType]()[topBarInfo.activeIndex ];
    const fullPathToPush = `${urlPrefix}${goBackPath}`;
    push(fullPathToPush);
  };

  return {
    goBack,
    isLoading,
    steps: topBarInfo.steps,
    activeIndex: topBarInfo.activeIndex,
  };
};
