import { client, clientWorkspaceBackend } from 'data/client';
import { InputParams } from 'model/parameters';
import {
  WorkspaceMemberRoleParams,
  WorkspaceMemberActivateParams,
  WorkspaceMemberRemoveParams,
  TransferWorkspaceParams,
  WorkspaceMemberPromitionValidity,
} from 'model/workspaceMembers';
import { WorkspaceMemberWithSeatInput } from 'seats/model/WorkspaceMemberWithSeats';
import {transformWorkspaceUsers} from "../transform/workspaceUsers";

export async function getWorkspaceUsers(
  workspaceId: string,
  params?: InputParams,
) {
  const { data } = await clientWorkspaceBackend.get<{ items: WorkspaceMemberWithSeatInput[] }>(
    `/workspaces/${workspaceId}/users/v1`,
    {
      params,
    },
  );
  return transformWorkspaceUsers(data.items);
}

export async function putWorkspaceOwner({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: TransferWorkspaceParams;
}) {
  const { data } = await client.put(`/workspaces/${workspaceId}/owner`, params);

  return data;
}

export async function deleteMember({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: WorkspaceMemberRemoveParams;
}) {
  const { data } = await client.delete(`/workspaces/${workspaceId}/members`, {
    data: params,
  });

  return data;
}

export async function putMemberRole({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: WorkspaceMemberRoleParams;
}) {
  const { data } = await client.put(
    `/workspaces/${workspaceId}/members/change-role`,
    params,
  );

  return data;
}

export async function putMemberStatus({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: WorkspaceMemberActivateParams;
}) {
  const { data } = await client.put(
    `/workspaces/${workspaceId}/members/activate`,
    params,
  );

  return data;
}

export async function getWorkspaceMemberPromotionValidity({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const { data } = await client.get<{
    items: WorkspaceMemberPromitionValidity[];
  }>(`/workspaces/${workspaceId}/members/promote-validity`);
  return data.items;
}
