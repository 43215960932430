import { AxiosError } from 'axios';
import { BackendError } from 'model/error';
import { WorkspaceDetails } from 'data/context/WorkspaceContext';

const isInvalidVat = (message: string) =>
  message.indexOf('VAT number') < message.indexOf('is not valid');

export const getErrorMessage = (
  error: any,
): { isField: boolean; message: string; key: string } => {
  const axiosError = ((error as AxiosError).response?.data as BackendError)
    .error;
  if (axiosError != null) {
    if (
      axiosError.message.includes('tax_id_invalid') ||
      isInvalidVat(axiosError.message)
    ) {
      return {
        isField: true,
        message: 'This VAT ID doesn’t seem to be valid.',
        key: 'vatNumber',
      };
    }
  }
  return {
    isField: false,
    message: '',
    key: 'server-error',
  };
};

export const getDefaultCountry = (country?: string) => country || 'US';

export const getPurchaseType = (isBusinessPurchase: boolean) =>
  isBusinessPurchase ? 'B2B' : 'B2C';

export const hasToUpgradeLegacySubscription = (
  workspaceDetails?: WorkspaceDetails,
) => workspaceDetails?.plan === 'LEGACY';
