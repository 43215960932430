import React from 'react';
import {Placeholder} from '@scriptaddicts/yamm-ui-components';
import {
  ComputedBillingPurchasePlansItem,
  ComputedBillingPurchasePlansItemDetail,
  StyledDifferenceText
} from 'seats/containers/Checkout/common/styles';
import Strong from 'components/Strong';
import {plurify} from 'utils/plurify';
import {PurchasePlanDisplayV2} from 'seats/containers/Checkout/common/types';
import {formatPriceSignOnly} from 'utils/priceFormat';
import {formatUserWorkspacePlan} from "seats/containers/Checkout/common/utils";

export function ComputedBillingPurchasePlans({plans}: Readonly<{ plans: PurchasePlanDisplayV2[] | undefined; }>) {
  return (
    <>
      {!plans ? (
        <StyledDifferenceText className='planLoadingPlaceHolder'>
          <span>
            <Placeholder type="paragraph" size="extra-large" width={80}/>
          </span>
          <span>
            <Placeholder type="paragraph" size="extra-large" width={62}/>
          </span>
        </StyledDifferenceText>
      ) : (
        plans.map((plan) => (
          <ComputedBillingPurchasePlansItem key={`${plan.quantity}-${plan.currency}-${plan.userPlan}`}>
            <ComputedBillingPurchasePlansItemDetail>
              <Strong>{plan.quantity} {formatUserWorkspacePlan(plan.userPlan)}</Strong>{' '}
              {plurify(plan.quantity, 'seat', 'seats')}
            </ComputedBillingPurchasePlansItemDetail>
            <ComputedBillingPurchasePlansItemDetail>
              <Strong>{formatPriceSignOnly(plan.totalAmount, plan.currency, 2)}</Strong>
            </ComputedBillingPurchasePlansItemDetail>
          </ComputedBillingPurchasePlansItem>
        ))
      )}
    </>
  );
}

