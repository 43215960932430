import {CHECKOUT, FROM, TO, NEW_WORKSPACE_SEATS, NEW_GMAIL_SEATS} from 'seats/data/queryParams';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';


export const CHECKOUT_TYPE = {
  NONE: -1,
  NEW_PLAN_PURCHASE: 1,
  WORKSPACE_SEATS_ADDED: 2,
};

const getNumber = (value: string | null): number => {
  if (!value) return 0;
  return parseInt(value, 10);
};

export type CheckoutResult = {
  checkoutType: number;
  newGmailPaidSeats: {
    quantity: number,
    plan: 'PRO',
  };
  newWorkspaceSeats: {
    quantity: number,
    plan: 'PRO',
  },
};


export const useConnect = (): CheckoutResult => {
  const { search } = useLocation();

  return useMemo(() => {
    const result: CheckoutResult = {
      checkoutType: CHECKOUT_TYPE.NONE,
      newGmailPaidSeats: {
        quantity: 0,
        plan: 'PRO',
      },
      newWorkspaceSeats: {
        quantity: 0,
        plan: 'PRO',
      }
    };
    const queryParams = new URLSearchParams(search);
    if (queryParams.get(CHECKOUT) === 'success') {
      const from = queryParams.get(FROM);
      const to = queryParams.get(TO);

      if (queryParams.get(NEW_GMAIL_SEATS) !== null) {
        result.newGmailPaidSeats.quantity = getNumber(queryParams.get(NEW_GMAIL_SEATS));
      }
      if (queryParams.get(NEW_WORKSPACE_SEATS) !== null) {
        result.newWorkspaceSeats.quantity = getNumber(queryParams.get(NEW_WORKSPACE_SEATS));
      }

      if (from === 'FREE' && to != null) {
        result.checkoutType = CHECKOUT_TYPE.NEW_PLAN_PURCHASE;
        return result;
      }

      if (result.newWorkspaceSeats != null && result.newWorkspaceSeats.quantity !== 0) {
        result.checkoutType = CHECKOUT_TYPE.WORKSPACE_SEATS_ADDED;
        return result;
      }
    }

    return result;
  }, [search]);
};
