import React from 'react';
import { Placeholder } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from 'seats/containers/Checkout/BillingDetailsSidebar/connect';
import LoadingScreen from 'components/LoadingScreen';
import {
  StyledNextButton,
  StyledStripeLogo,
  StyledBillingCycle,
  StyledTotalSection,
  ComputedBillingPurchasePlansItemDivider,
  ComputedBillingPurchasePlansItemDividerLine, StyledDifferenceText,
} from 'seats/containers/Checkout/common/styles';
import { from, useMediaQuery } from 'styles/media';
import { ComputedBillingPurchasePlans } from 'seats/containers/Checkout/common/components';
import {getNextButtonLabel, getNextLink} from "seats/containers/Checkout/BillingOptions/utils";

const BillingDetailsSidebar = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const {
    billingInterval,
    paymentInfo,
    isFetchingPaymentInfo,
    isLoading,
    checkoutFlowType,
    purchasePlansDisplay,
    seatQuantity,
    isSeatQuantityReady
  } = useConnect();
  const isTablet = useMediaQuery(from.tablet);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const nextUrl = getNextLink(checkoutFlowType);
  const nextButtonLabel = getNextButtonLabel(checkoutFlowType);
  const shouldDisplaySeatsPaymentInfo = seatQuantity > 0;
  return (
    shouldDisplaySeatsPaymentInfo ? <>
         <ComputedBillingPurchasePlans plans={purchasePlansDisplay}/>

        <ComputedBillingPurchasePlansItemDivider>
          <ComputedBillingPurchasePlansItemDividerLine />
        </ComputedBillingPurchasePlansItemDivider>
         {isFetchingPaymentInfo && !paymentInfo && (<StyledDifferenceText>
          <span>
            <Placeholder type="paragraph" size="extra-large" width={80}/>
          </span>
           <span>
          <Placeholder type="paragraph" size="extra-large" width={62}/>
        </span>
         </StyledDifferenceText>)}

        <StyledTotalSection>
          {!isFetchingPaymentInfo && paymentInfo && (<>
            <span>
              <StyledBillingCycle>Before taxes</StyledBillingCycle>
            </span>
            <span>
              <StyledBillingCycle>
              Billed {billingInterval === 'YEARLY' ? ' annually' : ' monthly'}
            </StyledBillingCycle>
         </span>
          </>)}
        </StyledTotalSection>


        <StyledNextButton
          aria-label={nextButtonLabel}
          data-log-event={nextUrl ? `go_to${nextUrl.replace(/\//g, '_')}` : 'go_to_undefined'}
          data-log-event-data={JSON.stringify({
            displayName: nextButtonLabel,
          })}
          onClick={onSubmit}
          disabled={isFetchingPaymentInfo || !isSeatQuantityReady}
        >
          {nextButtonLabel}
        </StyledNextButton>
        {isTablet && <StyledStripeLogo/>}
      </> :
    <>
      {isTablet && <StyledStripeLogo />}
    </>
  );
};

export default BillingDetailsSidebar;
