import currencyjs from 'currency.js';
import {captureException} from '@sentry/minimal';
import {BillingCurrency} from 'model/workspaceBilling';

/**
 * fallback for new formatPrice
 * @param value
 * @param currency
 */
export const formatPriceLegacy = (value: number, currency: string) => {
  const symbol = (() => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
      default:
        return '$';
    }
  })();
  const formattedValue = currencyjs(value, {
    symbol,
    precision: 2,
    decimal: ',',
    separator: '.',
    pattern: '#',
  }).format();
  return symbol === '$' ? `${formattedValue} US$` : `${formattedValue} €`;
};

export function formatPriceSignOnly(
  value: number,
  currency: BillingCurrency,
  precision: number = 0,
) {
  const formatSettings = (() => {
    const upperCaseCurrency = currency?.toUpperCase();
    switch (upperCaseCurrency) {
      case 'EUR':
        return {symbol: '€', pattern: '#!'};
      case 'USD':
      default:
        return {symbol: '$', pattern: '!#'};
    }
  })();

  return currencyjs(value, {
    symbol: formatSettings.symbol,
    precision,
    decimal: '.',
    pattern: formatSettings.pattern,
  }).format();
}

/**
 * format price using Intl (with fallback to old currencyjs formatting in case of error)
 * decimals are rounded. Ex: 10.155 -> 10.16
 * @param value
 * @param currency
 * @param locale: optional, pass undefined (or nothing) locale so the browser will automatically detect the user locale based on browser settings
 * @param options
 */
export const formatPrice = (
  value: number,
  currency: BillingCurrency,
  locale: string | undefined = undefined,
  options?: Intl.NumberFormatOptions,
): string => {
  try {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency,
      ...options,
    });
  } catch (e) {
    captureException(e);
    return formatPriceLegacy(value, currency);
  }
};
