import { Color } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { BillingCurrency, BillingInterval } from 'model/workspaceBilling';
import { formatPriceSignOnly } from 'utils/priceFormat';
import { getBillingIntervalLabel } from 'utils/checkout';
import { PageRestrictions } from 'data/hooks/restrictions/useRestrictions';
import { Links } from 'data/links';
import { plurify } from 'utils/plurify';
import {
  StyledListItem,
  LinksSection,
  StyledPlanContainer,
  PayPalIcon,
  RightCardTitle,
  RightCardTitleContainer,
  RightCardSubTitle,
} from './styles';
import { RenewalText } from './components';
import PlanSeatDetails from './PlanSeatDetails';
import { Card, CardTitleContainer, CardTitle, CardSubtitle } from '../styles';

export function calculatePrecision(value: number): number {
  return Math.round(value) !== value ? 2 : 0;
}

export type PlanProps = {
  name: string;
  assignedSeats: number;
  totalSeats: number;
  price: number;
  newPrice?: number;
  currency: BillingCurrency;
  billingCycle: BillingInterval;
  endDate: Date;
  isRenewal: boolean;
  isPayPal: boolean;
  isLegacy: boolean;
  tax: number;
  manageSubscriptionLink?: string;
  workspaceUrlPrefix: string;
  onCancelCycleChange?: () => void;
  pageRestrictions: PageRestrictions;
  isGmailUser: boolean | undefined;
};
const Plan = ({
  name,
  assignedSeats,
  totalSeats,
  currency,
  price,
  newPrice,
  billingCycle,
  tax,
  endDate,
  isRenewal,
  isLegacy,
  isPayPal,
  manageSubscriptionLink,
  workspaceUrlPrefix,
  onCancelCycleChange,
  pageRestrictions,
  isGmailUser,
}: PlanProps) => {
  const totalPrice = price + price * (tax / 100);

  const onManageSubscription = () =>
    window.open(manageSubscriptionLink, '_blank');

  const onManagePayPalSubscription = () =>
    window.open(Links.MANAGE_PAYPAL_PLAN, '_blank');

  const isLegacyPaypal = isLegacy && isPayPal;

  const getMonthlyPrice = (
    subscriptionPrice: number,
    cur: BillingCurrency,
    cycle: BillingInterval,
  ) => {
    const monthlyPrice =
      cycle === 'YEARLY' ? subscriptionPrice / 12 : subscriptionPrice;
    return formatPriceSignOnly(monthlyPrice, cur, 2);
  };

  return (
    <StyledPlanContainer>
      <Card>
        <CardTitleContainer>
          <CardTitle>Current plan: {name}</CardTitle>
          <CardSubtitle>
            {totalSeats} {plurify(totalSeats, 'seat', 'seats')}{' '}
          </CardSubtitle>
        </CardTitleContainer>
        <PlanSeatDetails
          {...{
            assignedSeats,
            totalSeats,
            workspaceUrlPrefix,
            pageRestrictions,
            isGmailUser,
          }}
        />
      </Card>
      <Card>
        {isLegacyPaypal && <PayPalIcon />}
        <RightCardTitleContainer>
          <RightCardTitle>
            <span style={{ fontWeight: 700 }}>
              {getMonthlyPrice(totalPrice, currency, billingCycle)}{' '}
            </span>
            {' / '}month
          </RightCardTitle>
          <RightCardSubTitle>
            <span style={{ fontWeight: 600 }}>
              {formatPriceSignOnly(
                totalPrice,
                currency,
                calculatePrecision(totalPrice),
              )}
            </span>
            {' billed '}
            {getBillingIntervalLabel(billingCycle, true)},{' '}
            {tax > 0 ? 'including taxes' : 'excluding taxes'}
            <RenewalText
              date={endDate}
              isRenewal={isRenewal}
              currency={currency}
              newPrice={newPrice}
              onCancelCycleChange={onCancelCycleChange}
            />
          </RightCardSubTitle>
        </RightCardTitleContainer>

        <LinksSection>
          {manageSubscriptionLink && (
            <>
              <StyledListItem onClick={onManageSubscription} key="key1">
                <Color variant="info">Manage subscription</Color>
              </StyledListItem>
              {!isLegacy && (
                <StyledListItem onClick={onManageSubscription} key="key2">
                  <Color variant="info">Update payment info</Color>
                </StyledListItem>
              )}
            </>
          )}
          {!isLegacy && (
            <StyledListItem onClick={onManageSubscription} key="key4">
              <Color variant="info">Invoice history</Color>
            </StyledListItem>
          )}
          {isLegacyPaypal && (
            <StyledListItem onClick={onManagePayPalSubscription} key="key5">
              <Color variant="info">Manage subscription</Color>
            </StyledListItem>
          )}
        </LinksSection>
      </Card>
    </StyledPlanContainer>
  );
};

export default Plan;
