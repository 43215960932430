import { useMemo } from 'react';
import {WorkspaceMemberWithSeat} from "seats/model/WorkspaceMemberWithSeats";
import { PendingUsers } from '../types';

export type UsePendingUsersProps = {
  selected: WorkspaceMemberWithSeat[];
};

export const usePendingUsers = ({ selected }: UsePendingUsersProps) => {
  const pendingUsers = useMemo(() => {
    return selected.filter((s) => s?.status === 'PENDING');
  }, [selected]);

  return useMemo<PendingUsers>(() => {
    return {
      count: pendingUsers.length,
      list: pendingUsers,
      features: {
        remove: true,
      },
    };
  }, [pendingUsers]);
};
