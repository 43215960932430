import {useEffect, useMemo, useState} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {FormikHelpers} from 'formik';
import {captureException} from '@sentry/react';
import {useHistory, useLocation} from 'react-router-dom';
import {
  logError, logStripeSessionCreatedEvent,
} from 'utils/userJourneyLogging';
import {useSeatCheckoutContext} from 'seats/data/context/CheckoutContext';
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {validationSchema} from 'seats/containers/Checkout/SeatsBillingInformation/validation';
import {SeatCheckoutFormValues} from 'seats/data/context/CheckoutContext/types';
import {getErrorMessage,} from 'seats/containers/Checkout/SeatsBillingInformation/utils';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import {PLAN, INTERVAL} from 'data/queryParams';
import EUcountries from 'seats/containers/Checkout/SeatsBillingInformation/eu_countries.json';
import {MapCheckoutFormToCheckoutRequestPropsV2, mapCheckoutFormToCheckoutRequestV2} from "seats/utils/mappers";
import {getDefaultComputeParams} from "seats/containers/Checkout/BillingDetailsSidebar/utils";
import {
  useComputeStripePaymentInfoV2,
  useCreateStripeCheckoutSessionV2
} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {PaidUserPlan} from "seats/data/requests/workspaceBilling";
import {getAvailablePlanForEmail} from "seats/utils/workspaceBillingUtil";
import {useMe} from "data/hooks/useMe";
import {SEAT_CHECKOUT_SESSION_FORM_KEY} from "seats/data/constants";

const useCheckQueryParams = () => {
  const { replace } = useHistory();
  const { url } = useWorkspaceLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(PLAN) && !searchParams.has(INTERVAL)) {
      replace(`${url}/checkout/details`);
    }
  }, [url]);
};

export const useConnect = () => {
  const stripe = useStripe();
  const {
    checkoutForm,
    setCheckoutForm,
    plan,
    billingInterval,
    seatQuantity
  } = useSeatCheckoutContext();

  const { pathname } = useWorkspaceLocation();
  const { search } = useLocation();
  const {
    id : workspaceId,
    details: workspaceDetails,
    user,
    otherWorkspaces,
    isLoading: isLoadingWorkspace,
  } = useWorkspaceContext();

  const userData = useMe();

  const [country, setCountry] = useState<string | undefined>(checkoutForm.country,);

  const isEuropeanCountry = useMemo(
    () => EUcountries.some((c) => c.id === country),
    [country],
  );

  const [isBusinessPurchase, setIsBusinessPurchase] = useState(
    checkoutForm.isBusinessPurchase,
  );
  const [redirecting, setRedirecting] = useState<boolean>(false);

  useEffect(() => {
    const handlePageHide = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  const [submitError, setSubmitError] = useState<string | undefined>();

  useCheckQueryParams();

  const paidUserPlan: PaidUserPlan | undefined =  useMemo(() => {
    if (userData?.data?.email && !userData.isLoading) {
      return getAvailablePlanForEmail(userData.data.email);
    }
    return undefined;
  }, [userData?.data?.email, userData?.isLoading]);

  const computeStripePaymentInfoRequestPayload = useMemo(() => {
    if (!paidUserPlan) {
      return undefined;
    }
    return getDefaultComputeParams(
      billingInterval,
      seatQuantity,
      paidUserPlan,
      country,
      isBusinessPurchase ? 'B2B' : 'B2C',
    );
  }, [
    billingInterval,
    plan,
    seatQuantity,
    paidUserPlan,
    country,
    isBusinessPurchase,
  ]);

  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfoV2(
    isLoadingWorkspace ? undefined : workspaceId,
    computeStripePaymentInfoRequestPayload,
  );

  const {
    mutateAsync,
    isLoading: isSubmitting,
  } = useCreateStripeCheckoutSessionV2();

  const onSubmit = async (
    values: SeatCheckoutFormValues,
    { setFieldError }: FormikHelpers<SeatCheckoutFormValues>,
  ) => {

    setCheckoutForm(values);
    setSubmitError(undefined);
    const stripePlans = paymentInfo?.stripePlans;
    if (workspaceId && stripePlans) {
      try {
        const workspaceIdCheckoutFormStripePlans: MapCheckoutFormToCheckoutRequestPropsV2 = {
          workspaceId, checkoutForm : values, stripePlans,
        };
        const data = await mutateAsync(mapCheckoutFormToCheckoutRequestV2(workspaceIdCheckoutFormStripePlans),);
        if (data && stripe) {
          logStripeSessionCreatedEvent({
            pagePath: pathname,
            search,
            user: userData.data,
            workspaceId,
            workspaceDetails,
            workspaceUser: user,
            otherWorkspaces,
            selectedPlan: plan,
            stripeCheckoutSessionId: data.stripeCheckoutSessionId,
          });
          setRedirecting(true);
          stripe
          .redirectToCheckout({
            sessionId: data.stripeCheckoutSessionId,
          })
          .finally(() => {
            setRedirecting(false);
          });
        }
        sessionStorage.setItem(SEAT_CHECKOUT_SESSION_FORM_KEY, JSON.stringify(values),);
      } catch (e) {
        window.scrollTo(0, 0);
        captureException(e);
        setRedirecting(false);
        const {message, isField, key} = getErrorMessage(e);
        if (isField) {
          setFieldError(key, message);
        } else {
          setSubmitError(key);
        }
        logError({
          errorType: key, pagePath: pathname, search, workspaceId, selectedPlan: plan,
        });
      }
    } else {
      window.scrollTo(0, 0);
      captureException(new Error('Workspace ID of stripe plans are not found'));
      setSubmitError('Workspace ID of stripe plans are not found');
    }
  };

  return {
    workspaceDetails,
    isLoading: isLoadingWorkspace,
    validationSchema,
    checkoutForm,
    isEuropeanCountry,
    setCountry,
    isBusinessPurchase,
    setIsBusinessPurchase,
    onSubmit,
    isSubmitting: isSubmitting || redirecting,
    isFetchingPaymentInfo,
    submitError,
  };
};
