import React, {useState} from 'react';
import {FlexContainer, Radio, Text} from '@scriptaddicts/yamm-ui-components';
import CheckoutLayout from "seats/containers/Checkout/Layout";
import BillingDetailsSidebar from "seats/containers/Checkout/BillingDetailsSidebar";
import {
  StyledTitle,
  StyledTitleWrapper,
  Upgrade,
  WorkspaceName,
} from "seats/containers/Checkout/common/styles";
import { MainContainerLoader } from "components/MainContainer";
import {
  AreYouBuying,
  OptionsFormContainer,
  RadioOption,
  Options,
  OptionsContainer,
  TeamSeatQuantitySpinnerContainer,
  TeamSeatQuantityMessage,
  ForMyselfQuantityMessage,
  BoldMessage,
} from "seats/containers/Checkout/BillingDetails/styles";
import { useConnect } from "seats/containers/Checkout/BillingDetails/connect";
import {NumberInputSpinner} from "seats/components/NumberInputSpinner";
import {MAX_USERS_PER_SPACE} from "../../../../data/constants";


export type BillingDetailsComponentProps = ReturnType<typeof useConnect>;

const BillingDetails = () => {
  const props = useConnect();

  const { isLoading, workspaceDetails, onSubmit, setSeatQuantity, seatQuantity, setIsSeatQuantityReady } = props;
  const [isForTeam, setIsForTeam] = useState(seatQuantity > 1);

  return (
    <CheckoutLayout.Container>
      <CheckoutLayout.Main>
        {isLoading || !workspaceDetails ? (
          <MainContainerLoader />
        ) : (
          <>
            <StyledTitleWrapper>
              <StyledTitle>
                <Upgrade>{`Upgrade `}</Upgrade>
                <WorkspaceName>{workspaceDetails.name}</WorkspaceName>
              </StyledTitle>
            </StyledTitleWrapper>
            <FlexContainer flex="vertical" gap="large">
              <OptionsFormContainer>
                <AreYouBuying>
                  Are you buying just for yourself or for a team?
                </AreYouBuying>
                <OptionsContainer>
                  <Options>
                    <RadioOption>
                      <Radio
                        checked={seatQuantity === 1}
                        name="billingDetailsChoice"
                        label="For myself"
                        onChange={() => {
                          setIsForTeam(false);
                          setSeatQuantity(1);
                        }}
                      />
                    </RadioOption>
                    <RadioOption>
                      <Radio
                        checked={seatQuantity > 1}
                        name="billingDetailsChoice"
                        label="For a team"
                        onChange={() => {
                          setIsForTeam(true);
                          setSeatQuantity(2);
                        }}
                      />
                    </RadioOption>
                  </Options>
                </OptionsContainer>
                {!isForTeam &&
                  <ForMyselfQuantityMessage>
                    You can always add more seats later.
                  </ForMyselfQuantityMessage>
                }
                {isForTeam && (<>
                  <TeamSeatQuantitySpinnerContainer>
                    <NumberInputSpinner
                      initialValue={seatQuantity}
                      minValue={2}
                      maxValue={MAX_USERS_PER_SPACE}
                      onChange={(value) => {
                        setIsSeatQuantityReady(true);
                        setSeatQuantity(value);
                      }}
                      onInvalid={() => {setIsSeatQuantityReady(false)}}
                    >
                    <Text type="span" style={{fontWeight: 600}}>seats</Text>
                    </NumberInputSpinner>
                  </TeamSeatQuantitySpinnerContainer>
                  <TeamSeatQuantityMessage>
                    <BoldMessage>One seat is reserved for you as the space owner.</BoldMessage><br />
                    You will be able to assign your seats to users after purchase.
                  </TeamSeatQuantityMessage>
                </>)}
              </OptionsFormContainer>
            </FlexContainer>
          </>
        )}
      </CheckoutLayout.Main>
      <CheckoutLayout.Sidebar>
        <BillingDetailsSidebar onSubmit={() => onSubmit()} />
      </CheckoutLayout.Sidebar>
    </CheckoutLayout.Container>
  );

};

export default BillingDetails;
