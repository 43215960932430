import {
  FormControl, FormLabel, TextArea,
} from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { useField } from 'formik';
import { OptionalLabel, StyledFromGroup } from './styles';

type TextAreaContainerProps = {
  label: string;
  name: string;
  maxLength: number;
  disabled: boolean;
  isOptional: boolean;
  placeholder: string;
  rows: number;
};

export const TextAreaField = ({
  label,
  name,
  maxLength,
  disabled,
  isOptional,
  placeholder,
  rows
}: TextAreaContainerProps) => {
  const [{ onBlur, onChange, value }, { error, touched }] = useField<string>(
    name,
  );

  return (
    <StyledFromGroup>
      <FormLabel htmlFor={name}>
        {label}
        {isOptional === true && <OptionalLabel> (optional)</OptionalLabel>}
      </FormLabel>
      <FormControl errorMessage={!!error && touched ? error : undefined}>
        <TextArea
          placeholder={placeholder}
          id={name}
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          hasError={!!error && touched}
          rows={rows}
        />
      </FormControl>
    </StyledFromGroup>
  );
};
