import React, { memo } from 'react';
import {
  FormGroup,
  FormLabel,
  RadioGroup,
} from '@scriptaddicts/yamm-ui-components';
import useConnect from 'seats/containers/Checkout/SeatsBillingInformation/CheckoutForm/RadioInput/connect';
import { Props } from 'seats/containers/Checkout/SeatsBillingInformation/CheckoutForm/RadioInput/types';
import { StyledFormControl } from 'seats/containers/Checkout/SeatsBillingInformation/CheckoutForm/RadioInput/styles';

const RadioInput = ({ className, label, name, onChange, disabled }: Props) => {
  const { handleChange, value, error, hasError } = useConnect({
    name,
    onChange,
  });

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <StyledFormControl errorMessage={hasError ? error : undefined}>
        <RadioGroup
          name={name}
          className={className}
          value={value.toString()}
          direction="row"
          disabled={disabled}
          options={[
            {
              label: 'Yes',
              value: 'true',
            },
            {
              label: 'No',
              value: 'false',
            },
          ]}
          onChange={(newValue) => handleChange(newValue === 'true')}
        />
      </StyledFormControl>
    </FormGroup>
  );
};

export default memo(RadioInput);
