import React from 'react';
import { CHECKOUT_TYPE, useConnect } from './connect';
import {SeatsAdded, SubscriptionStarted, SubscriptionUpdated} from './alerts';

const CheckoutResultAlerts = () => {
  const { checkoutType, newGmailPaidSeats, newWorkspaceSeats } = useConnect();

  return (
    <>
      {checkoutType === CHECKOUT_TYPE.NEW_PLAN_PURCHASE && (
        <>
          {newWorkspaceSeats.quantity > 0 && (
            <SubscriptionStarted
              seatQuantity={newWorkspaceSeats.quantity}
              workspacePlan={newWorkspaceSeats.plan}
            />
          )}
          {newGmailPaidSeats.quantity > 0 && (
            <SubscriptionStarted
              seatQuantity={newGmailPaidSeats.quantity}
              workspacePlan={newGmailPaidSeats.plan}
            />
          )}
        </>
      )}
      {checkoutType === CHECKOUT_TYPE.WORKSPACE_SEATS_ADDED && newWorkspaceSeats.quantity > 0 && (
        <>
          <SeatsAdded seatQuantity={newWorkspaceSeats.quantity}/>
          <SubscriptionUpdated/>
        </>
      )}
    </>
  );
};

export default CheckoutResultAlerts;
