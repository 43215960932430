import { User } from '@sentry/react';
import { isGmailAddress } from 'utils/userUtils';
import { RolloutRestrictions } from '../../../utils/rollout';
import {
  WorkspaceDetails,
  WorkspaceUser,
} from '../../context/WorkspaceContext';

export function restrictUserPage(
  restrictions?: RolloutRestrictions,
  details?: WorkspaceDetails,
  user?: User | null,
) {
  if (!restrictions || !details || !user) {
    // in case no data available assume the user can access it
    return false;
  }

  return (
    restrictions.USERS_PAGE ||
    (details.plan === 'LEGACY' &&
      (details.legacyType === 'PERSONAL' ||
        details.legacyType === 'PROFESSIONAL')) ||
    !!(
      user?.email &&
      (details?.plan === 'FREE' || isGmailAddress(user?.email) && details?.plan === 'PRO')
    )
  );
}

export function restrictBouncesPage(
  details?: WorkspaceDetails,
  user?: WorkspaceUser,
) {
  if (!details || !user) {
    return false;
  }

  return details.plan !== 'PRO' || user.plan === 'FREE';
}
