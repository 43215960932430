import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SeatCheckoutContextProvider } from 'seats/data/context/CheckoutContext';
import SeatsBillingInformation from "seats/containers/Checkout/SeatsBillingInformation";
import { useRequireUserFeature } from 'utils/useRequireUserFeature';
import BillingDetails from 'seats/containers/Checkout/BillingDetails';
import SeatsBillingAddSeats from "./SeatsBillingAddSeats";

const SeatsCheckout = () => {
  useRequireUserFeature('canAccessWorkspaceBilling');
  const { path } = useRouteMatch();
  return (
    <SeatCheckoutContextProvider>
      <Switch>
        <Route path={`${path}/details`} component={BillingDetails} />
        <Route path={`${path}/information`} component={SeatsBillingInformation} />
        <Route path={`${path}/add-seats`} component={SeatsBillingAddSeats} />
      </Switch>
    </SeatCheckoutContextProvider>
  );
};

export default SeatsCheckout;
