import {SeatCheckoutFlowType} from "seats/data/context/CheckoutContext/types";

export const getNextLink = (flowType: SeatCheckoutFlowType) => {
  if (flowType === 'GMAIL_PURCHASE' || flowType === 'DOMAIN_PURCHASE' || flowType === 'EMPTY_FLOW') {
    return '/checkout/information';
  }
  return null;
};

export const getNextButtonLabel = (flowType: SeatCheckoutFlowType): string => {
  if (flowType === 'DOMAIN_PURCHASE' || flowType === 'GMAIL_PURCHASE') {
    return 'Next: Billing information';
  }
  return 'Next: Review';
};