import React from 'react';
import { Alert, Text } from '@scriptaddicts/yamm-ui-components';
import { plurify } from 'utils/plurify';
import { formatWorkspacePlan } from 'seats/containers/Checkout/common/utils';

type SubscriptionStartedProps = {
  seatQuantity: number;
  workspacePlan: 'PRO' | 'PREMIUM';
};

export const SubscriptionStarted = ({ seatQuantity, workspacePlan }: SubscriptionStartedProps) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Well done! Your successfully purchased {seatQuantity} {formatWorkspacePlan(workspacePlan)} {plurify(seatQuantity, 'seat', 'seats')} for your space.
    </Text>
  </Alert>
);

export const SubscriptionUpdated = () => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Your subscription has been updated.
    </Text>
  </Alert>
);

export const SeatsAdded = ({ seatQuantity }: { seatQuantity: number }) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Well done! You successfully added {seatQuantity} {plurify(seatQuantity, 'seat', 'seats')} to your space.
    </Text>
  </Alert>
);