import { useMemo } from 'react';
import { useSeatCheckoutContext } from 'seats/data/context/CheckoutContext';
import { usePrices } from 'data/hooks/usePaymentPlans';
import { getPurchasePlansDisplayV2 } from 'seats/containers/Checkout/common/utils';
import {useBillingDetailsComputePaymentInfo} from "seats/containers/Checkout/common/hooks/useBillingDetailsComputePaymentInfo";

export const useConnect = () => {
  const {plan, billingInterval, checkoutFlowType, seatQuantity, isSeatQuantityReady} = useSeatCheckoutContext();
  const {paymentPlans} = usePrices();

  const {
    paymentInfo,
    isFetchingPaymentInfo,
    isLoading: isLoadingPaymentInfo,
  } = useBillingDetailsComputePaymentInfo();


  const purchasePlansDisplay = useMemo(() => {
    if (seatQuantity === 0) return [];
    if (paymentPlans && paymentInfo && paymentInfo.stripePlans) {
      return getPurchasePlansDisplayV2(
        paymentInfo.stripePlans,
        paymentPlans,
        paymentInfo.currency,
      );
    }
    return undefined;
  }, [paymentPlans, paymentInfo, seatQuantity, isLoadingPaymentInfo, isFetchingPaymentInfo]);

  return {
    paymentInfo,
    isLoading: isLoadingPaymentInfo,
    isFetchingPaymentInfo,
    checkoutFlowType,
    seatQuantity,
    billingInterval,
    purchasePlansDisplay,
    plan,
    isSeatQuantityReady,
  };
};

